import React, { useEffect } from "react";

import { useAuth } from "./AuthContext.js";
import amplitude from "amplitude-js";
import UAParser from "ua-parser-js";
import { fetchDataPost } from "../hooks/apiUtils.js"; 

export const Amplitude = () => {

};

const languages =
{
  "af": "Afrikaans (Afrikaans)",
  "am": "Amharic (አማርኛ)",
  "ar": "Arabic (عربي)",
  "bn": "Bengali (বুটে)",
  "bg": "Bulgarian (Български)",
  "my": "Burmese (မြန်မာနိုင်ငံ)",
  "ca": "Catalan (Calalana)",
  "zh": "Chinese (中国人)",
  "hr": "Croatian (Hrvatski)",
  "cs": "Czech (Čeština)",
  "da": "Danish (Dansk)",
  "nl": "Dutch (Nederlands)",
  "en": "English (English)",
  "et": "Estonian (Eesti)",
  "fil": "Filipino (Pilipino)",
  "fi": "Finnish (Suomalainen)",
  "fr": "French (Français)",
  "de": "German (Deutsch)",
  "el": "Greek (Ελληνικά)",
  "gu": "Gujarati (ગુજરાત)",
  "he": "Hebrew (עִברִית)",
  "hi": "Hindi (हिन्दी)",
  "hu": "Hungarian (Magyar)",
  "is": "Icelandic (Íslenskt)",
  "id": "Indonesian ",
  "ga": "Irish (Gaeilge)",
  "it": "Italian (Italiano)",
  "ja": "Japanese (日本)",
  "jv": "Javanese (Basa Jawa)",
  "kn": "Kannada (ಕನ್ನಡ)",
  "km": "Khmer (ខ្មែរ)",
  "ko": "Korean (한국인)",
  "lo": "Lao (ພາສາລາວ)",
  "lv": "Latvian (Latviski)",
  "lt": "Lithuanian (Lietuvių)",
  "mk": "Macedonian ",
  "ms": "Malay (Melayu)",
  "mt": "Maltese (Maltaca)",
  "mr": "Marathi (मराठी)",
  "nb": "Norwegian (Norsk)",
  "fa": "Persian (فارسی)",
  "pl": "Polish (Dialekt)",
  "pt": "Portuguese (Português)",
  "ro": "Romanian (Română)",
  "ru": "Russian (Русский)",
  "sr": "Serbian (Српски)",
  "si": "Sinhala (සිංහල)",
  "sk": "Slovak (Slovenský)",
  "sl": "Slovenian (Slovenščina)",
  "es": "Spanish (Español)",
  "sw": "Swahili (Svahili)",
  "sv": "Swedish (Svenska)",
  "ta": "Tamil (தமிழ்)",
  "te": "Telugu (తెలుగు)",
  "th": "Thai (ไทย)",
  "tr": "Turkish (Türkçe)",
  "uk": "Ukrainian ",
  "ur": "Urdu (اردو)",
  "uz": "Uzbek (O'Zbek Tili)",
  "vi": "Vietnamese ",
  "zu": "Zulu (Isizulu)"
}

export const sendAmplitudeData = (event_type, data, user, cta_content, cta_url, redirect_url) => {
  const compareDates = (date) => {
    const currentTimestamp = Date.now();

    if ((parseInt(currentTimestamp) - parseInt(date) < 600000)) {
      return true;
    } else {
      return false;
    }
  };

  
  const sendBackendData = (payload, user_id) => {
    var tmp_body = payload;
    tmp_body.hashed_id = user_id
    var body = {}
    body.event_type = "Feature Init"
    body.event_properties = tmp_body
    const response = fetchDataPost("https://ur9ejh6upi.execute-api.eu-central-1.amazonaws.com/default/DataTracker-SendAmplitudeDynamodb", body)
  };


  
  function getUserPreferredLanguage() {
    // Use navigator.languages[0] if available, otherwise fall back to navigator.language
    const preferredLanguage = navigator.languages
      ? navigator.languages[0]
      : navigator.language;
    return preferredLanguage;
  }
  function getAppVersion() {
    fetch('/firebase.json')
      .then(response => response.json())
      .then(data => {
        return (data.hosting.appVersion);
      });
  }
  function formatDate(timestamp) {
    const date = new Date(parseInt(timestamp));

    // Get the year, month, and day from the date object
    const year = date.getFullYear();
    // The getMonth method returns month from 0 to 11, need to add 1 to get correct month
    const month = date.getMonth() + 1;
    // PadStart ensures the month and day are two digits (e.g., '02' for February)
    const day = date.getDate().toString().padStart(2, '0');
    // Format the date components into a 'YYYY-MM-DD' string
    return `${year}-${month.toString().padStart(2, '0')}-${day}`;
  }

  if (event_type === "sign_up" && localStorage.getItem("amplitude-initialized") !== true) {
    localStorage.setItem("amplitude-initialized", true);
    if (compareDates(user.metadata.createdAt)) {
      var user_id = data.HashedId;
      var user_segment = data.UserSegment;
      var user_name = user.displayName
        ? user.displayName
        : user.email.split("@")[0];
      var amplitudeInstance = amplitude.getInstance();
      // Create an identify instance
      if (amplitudeInstance) {
        var identify = new amplitude.Identify();
        identify.set("ab_testing_segment", user_segment);
        identify.set(
          "dashboard_language",
          languages[localStorage.getItem("dashboard-language")] || "en"
        );
        identify.set("last_used_product", "recorditor");
        identify.set("sign_up_product", "recorditor");
        identify.set("platform", "web_app");
        identify.set("app_version", getAppVersion());
        identify.set("user_subscription", "trial");
        identify.set("subscription_package", data.Subscription?.Subscription_Type || "trial");

        identify.set("remaining_credit", 90);
        identify.set("order_language", languages[localStorage.getItem("transcript-language") || "en"]);
        identify.set("username", user_name);
        identify.set("screen_height", window.screen.height);
        identify.set("screen_width", window.screen.width);
        identify.set("sign_up_date", formatDate(user.metadata.createdAt));
        identify.set("sign_up_timestamp", user.metadata.createdAt);
        amplitudeInstance.setUserId(user_id);



        amplitudeInstance.identify(identify);

        var payload = {
          ab_testing_segment: user_segment,
          cta_content: cta_content,
          cta_url: cta_url,
          data_source: "frontend",
          redirect_url: redirect_url,
          app_version: getAppVersion(),

        }
        amplitudeInstance.logEvent("Sign Up", payload);
      } else {
        console.error("Amplitude instance is not initialized");
      }
    }
  } else if (event_type === "sign_in") {
    var user_id = data.HashedId;
    var user_segment = data.UserSegment;
    var user_name = user.displayName
      ? user.displayName
      : user.email.split("@")[0];
    var amplitudeInstance = amplitude.getInstance();
    // Create an identify instance
    if (amplitudeInstance) {
      var identify = new amplitude.Identify();
      identify.set("ab_testing_segment", user_segment);
      identify.set(
        "dashboard_language",
        languages[localStorage.getItem("dashboard-language")] || "en"
      );
      identify.set("last_used_product", "recorditor");
      identify.set("sign_in_product", "recorditor");
      identify.set("platform", "web_app");
      identify.set("app_version", getAppVersion());
      identify.set("user_subscription", data.SStatus);
      identify.set("remaining_credit", data.Minutes);
      identify.set("subscription_package", data.Subscription?.Subscription_Type || "trial");
      identify.set("order_language", languages[localStorage.getItem("transcript-language") || "en"]);
      identify.set("username", user_name);
      identify.set("screen_height", window.screen.height);
      identify.set("screen_width", window.screen.width);
      identify.set("sign_up_date", formatDate(user.metadata.createdAt));
      identify.set("sign_up_timestamp", user.metadata.createdAt);
      amplitudeInstance.setUserId(user_id);



      amplitudeInstance.identify(identify);

      var payload = {
        ab_testing_segment: user_segment,
        cta_content: cta_content,
        cta_url: cta_url,
        data_source: "frontend",
        redirect_url: redirect_url,
        app_version: getAppVersion(),

      }

      amplitudeInstance.logEvent("Sign In", payload);
    } else {
      console.error("Amplitude instance is not initialized");
    }
  } else if (event_type === "feature_engage") {
    var user_id = data.HashedId;
 
    var amplitudeInstance = amplitude.getInstance();
    // Create an identify instance
    if (amplitudeInstance) {
      var identify = new amplitude.Identify();
       //user attributes

      identify.set("remaining_credit", data.RemainingCredit);

      amplitudeInstance.setUserId(user_id);
      identify.set("last_used_product", "recorditor");
      amplitudeInstance.identify(identify);
      //event attributes
      var payload = {
        cta_content: cta_content,
        cta_url: cta_url,
        data_source: "frontend",
        feature_category: data.FeatureCategory,
        feature_subcategory: data.FeatureSubCategory,
        feature_name: data.FeatureName,
        feature_content: data.FeatureContent,
        feature_id: data.FeatureId,
        feature_software: "recorditor",
        feature_product: "recorditor",
        feature_platform: "web",
        feature_status: "production",
        feature_start_timestamp: Date.now(),
        credit_requirement: data.CreditRequirement,
        needs_credit: data.NeedsCredit,
        is_premium: false,

      }

      amplitudeInstance.logEvent("Feature Engage", payload);
    } else {
      console.error("Amplitude instance is not initialized");
    }
  } else if (event_type === "feature_init") {
    var user_id = data.HashedId;
   
    var amplitudeInstance = amplitude.getInstance();
    // Create an identify instance
    if (amplitudeInstance) {
      var identify = new amplitude.Identify();
      
      //user properties
     
      identify.set("remaining_credit", data.RemainingCredit);
      identify.set("last_used_product", "recorditor");
      amplitudeInstance.setUserId(user_id);



      amplitudeInstance.identify(identify);
     //event attributes
      var payload = {
        data_source: "frontend",
        feature_category: data.FeatureCategory,
        feature_subcategory: data.FeatureSubCategory,
        feature_name: data.FeatureName,
        feature_content: data.FeatureContent,
        feature_input_type: data.FeatureInputType,
        feature_id: data.FeatureId,
        feature_software: "recorditor",
        feature_platform: "web",
        feature_product: "recorditor",
        feature_status: "production",
        feature_start_timestamp: data.FeatureDataStartTimestamp ||  Date.now(),
        credit_requirement: data.CreditRequirement,
        needs_credit: data.NeedsCredit,
        order_id: data.OrderId,
        is_premium: false,     
      }
      sendBackendData(payload, data.HashedId);
      amplitudeInstance.logEvent("Feature Init", payload);
    } else {
      console.error("Amplitude instance is not initialized");
    }
  }
  else {
  }



};