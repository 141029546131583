import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './config';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, sendEmailVerification, signInWithCustomToken, GoogleAuthProvider, signInWithPopup, updateProfile } from "firebase/auth";
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../components/common/toast';
import Gleap from "gleap";
import countryLanguage from 'country-language';
import { fetchData } from '../hooks/apiUtils';
import { sendAmplitudeData } from './amplitude';
import { useModal } from '../components/common/Modal/ModalContext';
import { popularLanguages, allLanguages } from '../components/common/transcribeLanguageDropdown';
import { useTranslation } from '../components/common/LanguageProvider';
const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userData, setUserData] = useState(null); // [name, email, uid
    const [hashedId, setHashedId] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [planInfo, setPlanInfo] = useState(null);
    const { showModal } = useModal();
    const { t } = useTranslation();

    const auth = getAuth();
    auth.useDeviceLanguage();
    const navigate = useNavigate();
    const path = useLocation().pathname;

    useEffect(() => {
        console.log("location changed");
        console.log(location);
        getPlanInfo();
        getStatus();
    }, [location, hashedId]);

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    useEffect(() => {
        if (hashedId) {
            if (localStorage.getItem('fromDesktopApp')) {
                const customURL = `recorditor://login?uid=${encodeURIComponent(hashedId)}`
                window.location.href = customURL;
                localStorage.removeItem('fromDesktopApp');
            }
            /*

            if (localStorage.getItem('popUpShowedAccounts')) {
                const accs = JSON.parse(localStorage.getItem('popUpShowedAccounts'));
                if (accs.includes(hashedId)) {
                    return
                }
            }
            if (!isMobileDevice()) {
                showModal("DOWNLOAD-MODAL");
                localStorage.setItem('popUpShowedAccounts', JSON.stringify([...JSON.parse(localStorage.getItem('popUpShowedAccounts') || '[]'), hashedId]));
            }

            */
        }

    }, [hashedId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [path]);

    const getStatus = async () => {
        if (hashedId) {
            const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
            setUserData(response);
        }
    }
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    async function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)

    }
    const getPlanInfo = async () => {
        if (hashedId) {
            fetch("https://bfpu1bhqsa.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetPlanInfo?uid=" + hashedId).then(res => res.json()).then(data => {
                setPlanInfo(data);
            })
        }
    };
    function logout() {
        return auth.signOut();
    }

    function sendResetPasswordMail(email) {
        return sendPasswordResetEmail(auth, email);
    }

    function sendVerification(user) {
        return sendEmailVerification(user);
    }

    function signInWithToken(token) {
        return signInWithCustomToken(auth, token);
    }

    function googleLogin() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    }
    function updateProfileName(name) {
        return updateProfile(auth.currentUser, { displayName: name })
    }
    const automateLogin = async (uid, redirect) => {
        const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);

        if (response.status === 200) {
            const data = await response.json();
            console.log(data);
            console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
            const token = data["customToken"];
            await signInWithToken(token);
            console.log("keke")
            if (redirect) {

                console.log(redirect)
                navigate("/dashboard");

            } else {
                navigate('/dashboard');
            }

        } else {
            console.log('Error:', response.status, response.statusText);
        }

        console.log("kek");
    };
    const sendExtensionData = async (user, hashedId) => {
        try {
            let language = localStorage.getItem("dashboard-language");
            if (window.chrome && window.chrome.runtime) {
                let widgetData = JSON.stringify({
                    "userId": 'User#' + hashedId,
                    "email": user.email,
                    "language": language,
                });

                window.chrome.runtime.sendMessage(localStorage.getItem("extId"), { action: "loginUser", widgetData, language });
            }
        } catch (error) {
            console.log("Error accessing chrome runtime:", error);
        }
    }
    const findLanguage = async () => {
        try {
            const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
            const [iso639_1, countryCode] = browserLanguage.split("-");

            localStorage.setItem("dashboard-language", iso639_1);
            localStorage.setItem("dashboard-country", countryCode || 'US');
            localStorage.setItem("transcript-language", browserLanguage);
            window.location.reload();

        } catch (error) {
            console.error('Error detecting browser language:', error);
        }
    };

    function removeQueryParameters() {
        // Navigate without reloading the page
        navigate(path, { replace: true });
    }
    const directLogin = async (token) => {
        return signInWithToken(token);
    };

    const fetchLanguages = async (temp_hashedId, segment) => {
        if (!temp_hashedId) return;
        try {
            const queryParams = new URLSearchParams({
                user_id: hashedId,
                dashboard_language: localStorage.getItem("dashboard-language") || "en",
                app_name: 'recorditor'
            }).toString();
            const response = await fetch(`https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/getlanguages?${queryParams}`);
        
            if (response.ok) {
              const data = await response.json();
              localStorage.setItem("user_locales", JSON.stringify(data.user_specific_stt_locales));
              localStorage.setItem("all_locales", JSON.stringify(data.stt_locales));        
            } else {
              console.error('Failed to fetch languages:', response.statusText);
              localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
              localStorage.setItem("all_locales", JSON.stringify(allLanguages));
  
            }
          } catch (error) {
            console.error('Error fetching languages:', error);
            localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
            localStorage.setItem("all_locales", JSON.stringify(allLanguages));
          }
      };
    useEffect(() => {
        // This is a listener which Firebase provides to determine 
        // authentication state. It's automatically managed, so if the token 
        // is invalidated or expired, the user will be logged out automatically.
        const unsubscribe = onAuthStateChanged((auth), user => {
            setCurrentUser(user);
            setLoading(false);
            console.log(user)
            if (user) {
                if (user.emailVerified) {
                    const queryParams = new URLSearchParams(location.search);
                    // To retrieve a specific query parameter:
                    const option = queryParams.get('option');
                    const start = queryParams.get('start');
                    const lang = queryParams.get('lang');
                   
                    if (option) {
                        if (option === "transkriptor") {
                            sessionStorage.setItem("transkriptor-redirect", true);
                            removeQueryParameters();
                        }
                        if (start === "true") {
                            sessionStorage.setItem("start-record", true);
                        }
                    }
                    const isGoogleSignUp = user.providerData?.some(provider => provider.providerId === "google.com");
                    const currentTimestamp = Date.now();
                    const creationTimestamp = user.metadata.createdAt;
                    console.log("user is logged in")
                    fetch('https://v3t1p3ucic.execute-api.eu-central-1.amazonaws.com/default/AA-API-FireGetHashedID?uid='
                        + user.uid + '&app=Recorditor').then(response => response.json()).then(data => {
                            console.log(data)
                            const temp_hashedId = data.hashedId;
                            const is_new_user = data.is_new_user;
                            setHashedId(data.hashedId)
                            fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + data.hashedId).then(response => response.json()).then(data => {
                                setUserData(data)
                                let segment = data.UserSegment;
                                if(!localStorage.getItem("user_locales")){
                                    fetchLanguages(temp_hashedId, segment);
                                }
                                if(isGoogleSignUp && (parseInt(currentTimestamp) - parseInt(creationTimestamp) < 180000) && localStorage.getItem("google-clicked") === 'true' && is_new_user){
                                    localStorage.setItem("google-clicked", false);
                                    sendAmplitudeData("sign_up", data, user, "google_sign_up_button", "app.recorditor.com/signIn", "app.recorditor.com/dashboard");
                                }
                                else if(!isGoogleSignUp && localStorage.getItem("signedup-flag") === 'true' && is_new_user){
                                    localStorage.setItem("signedup-flag", false);
                                    sendAmplitudeData("sign_up", data, user, "sign_up_button", "app.recorditor.com/register", "app.recorditor.com/dashboard");
                                }
                                if(isGoogleSignUp && localStorage.getItem("google-clicked") === 'true'){
                                    localStorage.setItem("google-clicked", false);
                                    sendAmplitudeData("sign_in", data, user, "google_sign_in_button", "app.recorditor.com/signIn", "app.recorditor.com/dashboard");
                                }else if(localStorage.getItem("signedin-flag") === 'true'){
                                    localStorage.setItem("signedin-flag", false);
                                    sendAmplitudeData("sign_in", data, user, "sign_in_button", "app.recorditor.com/signIn", "app.recorditor.com/dashboard");
                                }
                                const queryParams = new URLSearchParams(location.search);
                                // Get the user agent string
                                var userAgentString = navigator.userAgent;
                                if (!localStorage.getItem("transcript-language")) {
                                    findLanguage();
                                } else {
                                    sendExtensionData(user, temp_hashedId);
                                }
                                // Detect the browser name
                                var browserName = "";
                                if (userAgentString.indexOf("Chrome") != -1) {
                                    browserName = "Chrome";
                                } else if (userAgentString.indexOf("Firefox") != -1) {
                                    browserName = "Firefox";
                                } else if (userAgentString.indexOf("Safari") != -1) {
                                    browserName = "Safari";
                                } else if (userAgentString.indexOf("Opera") != -1 || userAgentString.indexOf("OPR") != -1) {
                                    browserName = "Opera";
                                } else if (userAgentString.indexOf("Edge") != -1) {
                                    browserName = "Edge";
                                } else if (userAgentString.indexOf("MSIE") != -1 || !!document.documentMode == true) {
                                    browserName = "Internet Explorer";
                                } else {
                                    browserName = "Unknown browser";
                                }
                                Gleap.identify("User#" + temp_hashedId, {
                                    email: data.Mail,
                                    customData: {
                                        subscription: data.SStatus,
                                        segment: data.UserSegment,
                                        language: localStorage.getItem('dashboard-language'),
                                        browser: browserName
                                    },
                                },
                                    temp_hashedId
                                );
                                // To retrieve a specific query parameter:
                                const uid = queryParams.get('uid');
                                const redirect = queryParams.get('redirect');
                                if (redirect) {


                                    navigate("/dashboard");
                                } else {
                                    if (path === "/signIn" || path === "/register" || path === "/") {
                                        navigate('/dashboard');
                                    }
                                }
                            })
                        })
                } else {
                    sendVerification(user);
                    showToast(t("Please check your mailbox to verify your email."), { type: "success", position: "top-center" });

                    logout();

                    if (path !== "/signIn" && path !== "/register" && !path.startsWith("/authentication")) {
                        navigate('/signIn');
                        console.log("user is not logged in and path is not /signIn or /register")
                    }
                }
            } else {
                console.log("user is not logged in")
                const queryParams = new URLSearchParams(location.search);
                // To retrieve a specific query parameter:
                const uid = queryParams.get('uid');
                const redirect = queryParams.get('redirect');
                const token = queryParams.get('t');
                if (token) {
                    directLogin(token);

                }
                if (!redirect && path !== "/signIn" && path !== "/register" && !path.startsWith("/authentication") && !path.startsWith("/sharing")) {
                    navigate('/signIn');
                    console.log("user is not logged in and path is not /signIn or /register")
                } else if (uid && redirect) {
                    navigate("/dashboard");
                    automateLogin(uid, redirect);
                }
            }
        });

        // Cleanup subscription on unmount
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        hashedId,
        userData,
        planInfo,
        signup,
        login,
        logout,
        sendResetPasswordMail,
        sendVerification,
        signInWithToken,
        googleLogin,
        updateProfileName
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
