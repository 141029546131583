import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { showToast } from '../../components/common/toast';
import { useModal } from '../../components/common/Modal/ModalContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../Auth/AuthContext';
import { useTranslation } from '../../components/common/LanguageProvider';
import { useRecorder } from '../Recorder/RecorderContext';
import { sendAmplitudeData } from '../../Auth/amplitude';


const multipartUrl = 'https://v6b0h1y8jh.execute-api.eu-central-1.amazonaws.com/default/Recorditor-UploadMultipleParts?';
var upload_arr = [];
var upload_status = [];

const UploaderContext = createContext();

export const useUploader = () => useContext(UploaderContext);

export const UploadProvider = ({ children }) => {
	const { t } = useTranslation();
	const [uploadProgress, setUploadProgress] = useState(0);
	const { showModal } = useModal();
	const [selectedFiles, setSelectedFiles] = useState(null);
	const navigate = useNavigate();
	const [attachListener, setAttachListener] = useState(false);
	const [showUploadBox, setShowUploadBox] = useState(false);
	const { hashedId } = useAuth();
	const [uploadQueue, setUploadQueue] = useState([]);
	const navigateFile = useRef(true);
	const { featureId } = useRecorder();
	const featureStartTimeStamp = useRef(null);


	const [filesRouteLoading, setFilesRouteLoading] = useState(false);
	var attempt = 0;
	var uploadCounter = 1;
	const closeBoxTimeout = useRef(null);

	const startCloseTimeout = () => {
		if (closeBoxTimeout.current !== null) {
			clearTimeout(closeBoxTimeout.current);
		}

		closeBoxTimeout.current = setTimeout(() => {
			closeUploader(); // Function to call after timeout
		}, 15000);
	};

	const cancelCloseTimeout = () => {
		if (closeBoxTimeout.current !== null) {
			clearTimeout(closeBoxTimeout.current);
			closeBoxTimeout.current = null; // Reset the ref after clearing the timeout
		}
	};


	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (uploadQueue.some(file => file.status !== 'completed')) {
				event.preventDefault();
				event.returnValue = '';
			}
		};

		if (attachListener) {
			window.addEventListener('beforeunload', handleBeforeUnload);
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}
	}, [attachListener]);


	const onUploadMultipleFiles = async (files) => {
		showModal("TRANSCRIBE-MULTIPLE-FILES", { files: files }, { onTranscribeMultipleFiles });

	};

	const closeUploader = () => {
		setShowUploadBox(false);
		setUploadQueue([]);
		setUploadProgress(0);

	}

	const handleNavigateFile = async (orderId) => {
		navigateFile.current = false;
		setFilesRouteLoading(true);
		navigate('/files')

		const res = await fetch(`https://p8464z3n1m.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetOrderContent?oid=${orderId}`);
		if (res.ok) {
			const data = await res.json();
			if (data.recording.TStatus == "Completed") {
				navigate(`/recording/${orderId}`);
				attempt = 0;
				setFilesRouteLoading(false);
			}
			else {
				if (attempt < 5) {
					attempt++;
					setTimeout(() => handleNavigateFile(orderId), 3000);
				} else {
					setFilesRouteLoading(false);
					attempt = 0;
				}
			}

		}
	}

	const checkAndUploadNext = (service, language, files) => {
		let file
		setUploadQueue(prevQueue => {
			let newQueue = [...prevQueue];
			let index = newQueue.findIndex(file => file.status === 'waiting');
			if (index !== -1) {
				if (file) {
					return newQueue;
				}
				newQueue[index].status = 'uploading';
				file = files.find(file => file.name === newQueue[index].name);
			}
			return newQueue;
		}
		);
		if (file) {
			onTranscribeFile(language, service, file, false, files);

		} else {
			startCloseTimeout();
			navigateFile.current = true;
		}
	}

	const checkCredits = async (count) => {
		return true;
	}


	const onTranscribeMultipleFiles = async (language, service, files) => {

		if (files.length === 1) {
			const imputType = files[0].name.split('.').pop();
			featureStartTimeStamp.current = Date.now();
			const data = {
				HashedId: hashedId,
				FeatureCategory: "upload",
				FeatureSubCategory: "upload_local",
				FeatureName: "upload_local",
				CreditRequirement: files.length,
				FeatureContent: files.map(file => file.name).join(","),
				FeatureInputType: imputType,
				NeedsCredit: true,
				FeatureId: featureId.current,
				FeatureDataStartTimestamp: featureStartTimeStamp.current
			}
			sendAmplitudeData("feature_init", data, null, null, null, null);
		} else {

		}

		const res = await checkCredits(files.length);
		if (!res) {
			return;
		}
		cancelCloseTimeout()
		if (uploadQueue.some(file => file.status !== 'completed')) {
			const queueFiles = files.map(file => ({ 'name': file.name, 'status': 'waiting' }));
			setUploadQueue(prevQueue => [...prevQueue, ...queueFiles]);
		}
		else {
			// add first file as uploading and rest as waiting
			const queueFiles = files.map((file, index) => ({ 'name': file.name, 'status': index === 0 ? 'uploading' : 'waiting' }));
			setUploadQueue(prevQueue => [...prevQueue, ...queueFiles]);

		}

		onTranscribeFile(language, service, files[0], true, files);
	};
	const onTranscribeFile = async (language, service, file, flag, files) => {

		if (!flag)
			flag = false;
		handleUpload(file, language, service, flag, files);
	};

	const showUploadModal = () => {
		showModal("MULTIPLE-UPLOAD", { onUploadMultipleFiles })
	}

	const handleUpload = async (file, language, service, flag, files) => {
		console.log(file);
		if (flag && selectedFiles === 0) {
			navigate('/files');
			return;
		}
		if (!file) return;
		setShowUploadBox(true);
		setAttachListener(true);



		multipartUpload(file, hashedId, navigate, setUploadProgress, language, service, flag, files);
		return;
	};

	const multipartUpload = async (file, hashedId, navigate, setUploadProgress, language, service, flag, files) => {
		let partSize = 5 * 1024 * 1024; // 5 MB
		let fileSize = file.size;
		let filename = file.name;

		if (fileSize > 1024 * 1024 * 1024 * 3)
			partSize = 20 * 1024 * 1024; // 10 MB
		else if (fileSize > 1024 * 1024 * 1024) {
			partSize = 10 * 1024 * 1024; // 20 MB
		}
		const fileExtension = filename.split('.').pop();
		const fileFormat = fileExtension === 'mp3' ? 'mp3' : null;
		const partCount = Math.ceil(fileSize / partSize);
		let multipartInitiateUrl = multipartUrl + `fileSize=${fileSize}&uid=${hashedId}&filename=${encodeURIComponent(removeSpecialCharsAndTrim(filename))}&partCount=${partCount}&partSize=${partSize}&flag=true&file_format=${fileFormat}`;
		if (files.length === 1) {
			multipartInitiateUrl += `&feature_id=${featureId.current}&feature_start_timestamp=${featureStartTimeStamp.current}`;
		} else {
			const newFeatureId = featureId.current + "-" + uploadCounter;	
			featureStartTimeStamp.current = Date.now();

			multipartInitiateUrl += `&feature_id=${newFeatureId}&feature_start_timestamp=${featureStartTimeStamp.current}`;
			const imputType = fileExtension;
			const data = {
				HashedId: hashedId,
				FeatureCategory: "upload",
				FeatureSubCategory: "upload_local",
				FeatureName: "upload_local",
				CreditRequirement: 1,
				FeatureContent: filename,
				FeatureInputType: imputType,
				NeedsCredit: true,
				FeatureId: newFeatureId,
				FeatureDataStartTimestamp: featureStartTimeStamp.current
			}
			sendAmplitudeData("feature_init", data, null, null, null, null);

			uploadCounter++;
		}

		fetch(multipartInitiateUrl).then(response => response.json()).then(data => {
			const presignedUrls = data.presignedUrls;
			const uploadId = data.uploadId;
			const orderId = data.orderId;
			const limited = data.limited;
			console.log(presignedUrls);
			var partList = [];
			for (let i = 0; i < presignedUrls.length; i++) {
				const partNumber = presignedUrls[i].part_number;
				const presignedUrl = presignedUrls[i].presigned_url;
				const start = i * partSize;
				console.log(start)

				const end = Math.min((i + 1) * partSize, fileSize);
				const filePart = file.slice(start, end);
				upload_arr.push(0);
				let temp_arr = {
					url: presignedUrls[i],
					status: "started"
				}
				upload_status[i + 1] = temp_arr

				// Assuming 'presignedUrl' and 'fileChunk' are already defined
				partList.push({
					ETag: '',
					PartNumber: i + 1
				});

				let content_arr = {
					uploadId: uploadId,
					filename: filename,
					partCount: partCount,
					partList: partList,
					upload_arr: upload_arr,
					file_size: fileSize,
					language: language,
					service: service
				}
				upload_status[0] = content_arr;
				upload_part(presignedUrl, i, uploadId, partList, filename, multipartUrl, filePart, partCount, file, navigate, hashedId, setUploadProgress, language, service, flag, files, orderId, limited, fileFormat)
			}
		})

	};
	const upload_part = async (
		presignedUrl, i, uploadId, partList, filename,
		apiUrl, filePart, partCount, file, navigate, hashedId, setUploadProgress, language, service, multiFlag, files, orderId, limited, fileFormat
	) => {
		try {
			// Upload a part of the file using the presigned URL
			let uploadCid = hashedId
			const partResponse = await axios.put(presignedUrl, filePart, {
				headers: {
					'Content-Type': 'application/octet-stream'
				},
				onUploadProgress: (e) => {
					console.log(e);

					upload_arr = upload_status[0].upload_arr;
					upload_arr[i] = e.loaded;
					const sum = upload_arr.reduce((acc, val) => acc + val, 0);
					upload_status[0].loaded = upload_arr;

					// Calculate the percentage and ensure it does not exceed 100%
					const percentComplete = Math.min((sum / upload_status[0].file_size) * 100, 100);

					setUploadProgress(percentComplete);
					console.log(percentComplete);

				},
			});

			if (partResponse.status >= 200 && partResponse.status < 300) {
				console.log(filePart + " uploaded");
				const etag = partResponse.headers.etag;

				if (partList.length > 0) {
					partList[i].ETag = etag;
				}

				upload_status[i + 1].status = "completed";

				let flag = true;
				for (let j = 1; j < upload_status.length; j++) {
					if (upload_status[j].status !== "completed") {
						flag = false;
						break;
					}
				}


				if (flag) {
					let canceled = false

					// if file is not in queue, it means it is cancelled so return
					setUploadQueue(prevQueue => {
						if (!prevQueue.some(f => f.name === filename)) {
							canceled = true;
							return prevQueue;
						}
						return prevQueue;
					}
					);
					if (canceled) {
						checkAndUploadNext(service, language, files);
						return;
					}

					// All parts have been uploaded, complete the multipart upload

					// Step 3: Notify your server about the successful upload
					let endpointUrl = `https://9wl4cxv3d1.execute-api.eu-central-1.amazonaws.com/default/Recorditor-MultipleUploadFinisher?uid=${uploadCid}&orderid=${orderId}&filename=${encodeURIComponent(removeSpecialCharsAndTrim(filename))}&uploadId=${uploadId}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&file_format=${fileFormat}`;

					const endpointResponse = await fetch(endpointUrl);
					const endpointData = await endpointResponse.json();
					console.log('Endpoint data:', endpointData);
					showToast(t("Uploaded File") + ": " + file.name, { type: "success" });
					// make its status as completed
					setUploadQueue(prevQueue => {
						let newQueue = prevQueue.map(file => {
							if (file.name === filename) {
								file.status = "completed";
							}
							return file;
						});
						return newQueue;
					});

					if (navigateFile.current) {
						handleNavigateFile(orderId);
					}

					checkAndUploadNext(service, language, files);
				}
			} else {
				console.error(`Failed to upload part ${i + 1}: ${partResponse.status}`);
				// Your retry logic and snackbar call goes here
			}

		} catch (error) {
			console.error("Error during upload:", error);
			// Your error logic goes here
		}
	}

	const removeSpecialCharsAndTrim = (filename) => {
		// Remove special characters (#, &, *) and trim whitespace
		let temp = filename.replace(/[&#*]/g, '').trim();
		return temp;
	};


	return (
		<UploaderContext.Provider value={{ uploadProgress, showUploadBox, setUploadQueue, setShowUploadBox, uploadQueue, closeUploader, showUploadModal, filesRouteLoading, setFilesRouteLoading }}>
			{children}
		</UploaderContext.Provider>
	);
};

export default UploaderContext;