import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useModal } from "../common/Modal/ModalContext";
function SidebarItem({
  link,
  icon,
  name,
  setActive,
  isActive,
  isBlocked,
  handleCloseSidebar,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    if (link === "language") {
      showModal("LANGUAGE-MODAL");
    } else if (link === "download") {
      showModal("DOWNLOAD-MODAL", { onDownloadDesktopApp });
    } else if (
      location.pathname.startsWith("/editor") ||
      location.pathname.startsWith("/sharing")
    ) {
      showModal("CONFIRM-EDITOR", { link }, { onLeaveEditor });
    } else if (isBlocked && location.pathname.startsWith("/dashboard")) {
      showModal("CONFIRM-UPLOADER", { link }, { onLeaveEditor });
    } else if (isBlocked && location.pathname.startsWith("/recorder")) {
      showModal("CONFIRM-RECORDER", { link }, { onLeaveEditor });
    } else {
      setActive(name.toLowerCase()); // Set the active item when clicked
      navigate(link);
      console.log("link", link);
    }

    handleCloseSidebar();
  };
  const onLeaveEditor = (link) => {
    if (link === "language") {
      showModal("LANGUAGE-MODAL");
    } else if (link === "download") {
      showModal("DOWNLOAD-MODAL");
    } else {
      setActive(name.toLowerCase()); // Set the active item when clicked
      if (location.pathname.startsWith("/recorder")) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
      navigate(link);
    }
  };

  const onDownloadDesktopApp = () => {
    var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var isWin = navigator.platform.toUpperCase().indexOf("WIN") >= 0;

    if (isMac) {
      window.open(
        "https://recorditor-updates.s3.eu-central-1.amazonaws.com/Recorditor-1.0.0.dmg"
      );
    }
  };

  const links = {
    "/files": "files",
    "/dashboard": "dashboard",
    "/folders": "folders",
    "/workspaces": "workspaces",
    "/rewards": "rewards",
    "/account": "account",
    "/enterprise": "enterprise",
  };
  useEffect(() => {
    console.log("location.pathname", location.pathname);
    let temp_link = links[location.pathname];
    console.log("temp_link", location.pathname);
    if (temp_link) setActive(temp_link);
  }, [location.pathname]);
  return (
    <a
      className={`sidebar-list ${isActive ? "active" : ""}`} // Conditionally add 'active' class
      onClick={handleClick} // Add click handler
      href={link}
    >
      <span className="sidebar-list-icon">{icon}</span>
      {name}
    </a>
  );
}

export default SidebarItem;
