import recordPng from "../../assets/img/icon/record-btn-icon.svg"
import { useRecorder } from './RecorderContext';
import { useModal } from '../../components/common/Modal/ModalContext';
import { useTranslation } from "../../components/common/LanguageProvider";
import { sendAmplitudeData } from "../../Auth/amplitude";
import { useAuth } from "../../Auth/AuthContext";
import { v4 as uuidv4 } from 'uuid';

const RecorderComponent = ({isTrial}) => {
  const { t } = useTranslation();
  const { showRecordModal, featureId } = useRecorder();
  const {showModal} = useModal();
  const isChrome = !!window.navigator.userAgent.match(/Chrome/i) && !window.navigator.userAgent.match(/Edge/i);
	const { hashedId } = useAuth();

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const showSettingsModal = () => {
    featureId.current = uuidv4();
    const data = {
      HashedId: hashedId,
      FeatureCategory: "record",
      FeatureSubCategory: "record_video",
      FeatureName: "record",
      CreditRequirement: 1,
      NeedsCredit: true,
      FeatureId: featureId.current

    }
    sendAmplitudeData("feature_engage", data, null, "dashboard_record_button", "app.recorditor.com/dashboard", null);	
		showRecordModal()
	}

  return (
    <>
     
    {isMobileDevice() ? (
      <div class="col-lg-4 card-margin">
      <div class="card card-padding flex-center card-100 text-center">
         
      <div class="img-icon-list">
            <span className='pointer round-color-icon red-bg recorder-span'><img src={recordPng} class="img-fluid recorder-img" alt="img" /></span>
          </div>

             <div className="w-100 d-sm-flex d-block align-items-center justify-content-center">
                 <p class="ft-18-title"> {t("Please use a computer to record your screen!")}</p>
               
             </div>
             
         </div>
     </div>
    ) :(
   
 
      <>
      
      <div className='col-lg-8 card-margin'>
                  <div className='card card-padding card-100 text-center card-hover'  onClick={showSettingsModal}>
                    <div class="img-icon-list">
                    <span className='pointer round-color-icon red-bg recorder-span'><img src={recordPng} class="img-fluid recorder-img"  alt="img"/></span>
                    </div>
                    <p class="ft-18-title">{t("Record")}</p>
                    <p>{t("Record Your Screens Directly Using Recorditor")}</p>
                  </div>
                </div>
      </>
  
    )}

    </>
  )

}

export default RecorderComponent;