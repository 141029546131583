import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from './LanguageProvider';
function ServiceDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const [selectedService, setSelectedService] = useState(t('Standard'));
    props.onServiceChange(localStorage.getItem("transcript-service") || "Standard");
    const topDropdown = props.topDropdown;

    const dropdownRef = useRef(null);
    const handleServiceClick = (event) => {
        const service = event.target;
        if (service) {
            setSelectedService(t(service.innerHTML));
            setIsOpen(false);
            props.onServiceChange(service.getAttribute('value'));
            localStorage.setItem("transcript-service", service.getAttribute('value'));
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            <div ref={dropdownRef} className={`selectBox ${isOpen ? 'show' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                <div className="form-control round-input selectBox__value">
                    {selectedService}
                </div>
                {isOpen && (
                    <div class={`dropdown-menu ${topDropdown ? 'select-top-dropdown-menu' : ''}`} onClick={handleServiceClick}>
                        <a href="#" class="dropdown-item active" value="Standard">{t("Standard")}</a>
                        <a href="#" class="dropdown-item" value="Subtitle">{t("Subtitle")}</a>
                        <a href="#" class="dropdown-item" value="8">{t("Speaker Separated")}</a>
                    </div>
                )}
            </div>

        </>
    );
}

export default ServiceDropdown;