import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../Auth/AuthContext";
import { showToast } from "./toast";
import { Link } from "react-router-dom";
import { useTranslation } from "./LanguageProvider";
function LanguageDropdown(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { hashedId } = useAuth();
  var temp_lang = localStorage.getItem("transcript-language") || "EN";
  const dropdownRef = useRef(null);
  const activeRef = useRef(null)
  const storedLocales = JSON.parse(localStorage.getItem("user_locales")) || popularLanguages;
  const storedAllLocales = JSON.parse(localStorage.getItem("all_locales")) || languages;
  const topDropdown = props.topDropdown;

  const handleLanguageClick = (event) => {
    const language = event.target;
    if (language) {
      setIsOpen(!isOpen);
      setSelectedLanguage(language.innerHTML);
      setIsOpen(false);
      props.onLanguageChange(language.getAttribute("value"));
      if (
        language.getAttribute("value") !==
        localStorage.getItem("transcript-language")
      ) {
        showToast(
          t("Transcription language changed to ") + language.innerHTML,
          { type: "success" }
        );
      
      }

      setLanguage(language.getAttribute("value"));
      localStorage.setItem(
        "transcript-language",
        language.getAttribute("value")
      );
      setTimeout(() => {
        fetchLanguages();
      }, 1000);
      
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const findLanguage = (langKey) => {
    console.log("langKey: " + langKey);
  
    // Prioritize the check for the full langKey first.
    if (languages[langKey]) {
      props.onLanguageChange(langKey);
      return languages[langKey];
    }
  
    // If full langKey is not found, split and get just the language part
    const langOnly = langKey.split("-")[0];
    console.log("langOnly: " + langOnly);
  
    // Check the uppercase version of the shortened langKey (e.g., TR)
    if (languages[langOnly.toUpperCase()]) {
      props.onLanguageChange(langOnly.toUpperCase());
      return languages[langOnly.toUpperCase()];
    }
    console.log("languages[langOnly.toUpperCase()]: " + languages[langOnly.toUpperCase()]);
  
    // If uppercase shortened langKey is not found, try lowercase (e.g., en)
    if (languages[langOnly.toLowerCase()]) {
      props.onLanguageChange(langOnly.toLowerCase());
      return languages[langOnly.toLowerCase()];
    }
  
    // If none are found, default to English (EN)
    props.onLanguageChange("EN");
    return languages["EN"];
  };
  
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  useEffect(() => {
    if (temp_lang) setSelectedLanguage(findLanguage(temp_lang));
    setLanguage(temp_lang);
  }, [temp_lang]);
  const setLanguage = async (langKey) => {
    fetch(
      "https://ma0vsq4kd6.execute-api.eu-central-1.amazonaws.com/default/setUserLanguagebyHashedId?uid=" +
        hashedId +
        "&language=" +
        langKey
    )
      .then((response) => response.json())
      .then((data) => {});
  };
  // ... other state and hooks ...
  const [inputValue, setInputValue] = useState("");
  const [filteredPopularLanguages, setFilteredPopularLanguages] = useState(storedLocales);
  const [filteredAllLanguages, setFilteredAllLanguages] = useState(storedAllLocales);

  const filterLanguages = (input) => {
    setInputValue(input);
    const inputLower = input.toLowerCase();
    
    console.log('Filter input:', input);
  
    const popularFiltered = Object.entries(storedLocales).filter(([_, name]) => name.toLowerCase().includes(inputLower));
    const allFiltered = Object.entries(storedAllLocales).filter(([_, name]) => name.toLowerCase().includes(inputLower));
  
    console.log('Popular filtered:', popularFiltered);
    console.log('All filtered:', allFiltered);
  
    setFilteredPopularLanguages(Object.fromEntries(popularFiltered));
    setFilteredAllLanguages(Object.fromEntries(allFiltered));
  };

  const fetchLanguages = async () => {
    if (!hashedId) return;
    try {
        const queryParams = new URLSearchParams({
            user_id: hashedId,
            dashboard_language: localStorage.getItem("dashboard-language") || "en",
            app_name: 'recorditor'
        }).toString();
        const response = await fetch(`https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/getlanguages?${queryParams}`);

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem("user_locales", JSON.stringify(data.user_specific_stt_locales));
            localStorage.setItem("all_locales", JSON.stringify(data.stt_locales));
        } else {
            console.error('Failed to fetch languages:', response.statusText);
            localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
            localStorage.setItem("all_locales", JSON.stringify(allLanguages));
        }
    } catch (error) {
        console.error('Error fetching languages:', error);
        localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
        localStorage.setItem("all_locales", JSON.stringify(allLanguages));
    }
};
  const handleKeyDown = (e) => {
    setInputValue(e.target.value)
  };

  /*useEffect(() => {
    if (isOpen && activeRef.current) {
      activeRef.current.scrollIntoView({block: 'center' });
    }
  }, [isOpen]);*/
  return (
    <>
      <div
        ref={dropdownRef}
        className={`selectBox ${isOpen ? "show" : ""}`}
      >
        <div className="form-control round-input selectBox__value" tabIndex="0" onClick={()=> setIsOpen(!isOpen)}>
          {selectedLanguage}
        </div>
        {isOpen && (
          <div className={`dropdown-menu ${topDropdown ? 'select-top-dropdown-menu' : ''}`}>
            <div className="language-group">
            <div class="mb-2 table-search input-icon-div d-lg-inline-block d-block ms-auto mt-lg-0 mt-3">
								<input type="text" class="form-control round-input" name="" placeholder={t("Search...")} value={inputValue}  onChange={(e) => filterLanguages(e.target.value)} />
								<span class="input-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
									  	<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
									</svg>
								</span>
							</div>
              <h5 className="label-translate m-2">{t("Popular Languages")}</h5>
              {Object.entries(filteredPopularLanguages).map(([code, label], index) => (
                <Link
                ref={selectedLanguage === label ? activeRef : null}

                  className={`dropdown-item ${
                    selectedLanguage === label ? "active" : ""
                  }`}
                  value={code}
                  onClick={handleLanguageClick}
                >
                  {label}
                </Link>
              ))}
            </div>
            <div className="language-group">
              <h5 className="label-translate m-2">All Languages</h5>
              {Object.entries(filteredAllLanguages).map(([code, label], index) => (
                <Link
                ref={selectedLanguage === label ? activeRef : null}

                  className={`dropdown-item ${
                    selectedLanguage === label ? "active" : ""
                  }`}
                  value={code}
                  onClick={handleLanguageClick}
                >
                  {label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default LanguageDropdown;

export const popularLanguages = {
  PT: "Portuguese (Português)",
  EN: "English (English)",
  ES: "Spanish (español)",
  TR: "Turkish (Türkçe)",
  DE: "German (Deutsch)",
  IT: "Italian (Italiano)",
  AR: "Arabic (عربي)",
  FR: "French (Français)",
  ID: "Indonesian (bahasa Indonesia)",
  KO: "Korean (한국인)",
  JA: "Japanese (日本)",
  ZH: "Chinese (中国人)",
  HI: "Hindi (हिंदी)",
  RU: "Russian (Русский)",
  TH: "Thai (แบบไทย)",
  VI: "Vietnamese (Tiếng Việt)",
};

export const allLanguages = {
  "af-ZA": "Afrikaans (South Africa) (Afrikaans (Suid-Afrika))",
  "am-ET": "Amharic (Ethiopia) (አማርኛ (ኢትዮጵያ))",
  "ar-DZ": "Arabic (Algeria) (العربية (الجزائر))",
  "ar-BH":
    "Arabic (Bahrain), modern standard (العربية (البحرين) ، المعيار الحديث)",
  "ar-EG": "Arabic (Egypt) (العربية (مصر))",
  "ar-IQ": "Arabic (Iraq) (العربية (العراق))",
  "ar-IL": "Arabic (Israel) (العربية (إسرائيل))",
  "ar-JO": "Arabic (Jordan) (العربية (الأردن))",
  "ar-KW": "Arabic (Kuwait) (العربية (الكويت))",
  "ar-LB": "Arabic (Lebanon) (العربية (لبنان))",
  "ar-LY": "Arabic (Libya) (العربية (ليبيا))",
  "ar-MA": "Arabic (Morocco) (العربية (المغرب))",
  "ar-OM": "Arabic (Oman) (العربية (عمان))",
  "ar-PS": "Arabic (Palestinian Authority) (العربية (السلطة الفلسطينية))",
  "ar-QA": "Arabic (Qatar) (العربية (قطر))",
  "ar-SA": "Arabic (Saudi Arabia) (العربية (المملكة العربية السعودية))",
  "ar-SY": "Arabic (Syria) (العربية (سوريا))",
  "ar-TN": "Arabic (Tunisia) (العربية (تونس))",
  "ar-AE": "Arabic (United Arab Emirates) (العربية (الإمارات العربية المتحدة))",
  "ar-YE": "Arabic (Yemen) (العربية (اليمن))",
  "BN": "Bengali (বাংলা)",
  "bg-BG": "Bulgarian (Bulgaria) (български (България))",
  "MY": "Burmese (Myanmar) (ဗမာ (မြန်မာ))",
  "ca-ES": "Catalan (Spain) (català (Espanya))",
  "zh-HK": "Chinese (Cantonese, Traditional) (中文（粤语、繁体）)",
  "zh-CN": "Chinese (Mandarin, Simplified) (中文（普通话、简体）)",
  "zh-TW": "Chinese (Taiwanese Mandarin) (中文（台湾普通话）)",
  "hr-HR": "Croatian (Croatia) (hrvatski (Hrvatska))",
  "cs-CZ": "Czech (Czech) (český (český))",
  "da-DK": "Danish (Denmark) (Dansk (Danmark))",
  "nl-BE": "Dutch (Belgium) (Nederlands (België))",
  "nl-NL": "Dutch (Netherlands) (Nederlands (Nederland))",
  "en-AU": "English (Australia) ",
  "en-CA": "English (Canada) ",
  "en-GH": "English (Ghana) ",
  "en-HK": "English (Hong Kong) ",
  "en-IN": "English (India) ",
  "en-IE": "English (Ireland)",
  "en-KE": "English (Kenya) ",
  "en-NZ": "English (New Zealand) ",
  "en-NG": "English (Nigeria) ",
  "en-PH": "English (Philippines)",
  "en-SG": "English (Singapore) ",
  "en-ZA": "English (South Africa) ",
  "en-TZ": "English (Tanzania) ",
  "en-GB": "English (United Kingdom)",
  "en-US": "English (United States) ",
  "et-EE": "Estonian (Estonia) (eesti keel (Eesti))",
  "fil-PH": "Filipino (Philippines) (filippiiniläinen (Filippiinit))",
  "FI": "Finnish (Finland) (suomi (Suomi))",
  "fr-BE": "French (Belgium) (Français (Belgique))",
  "fr-CA": "French (Canada) (Français (Canada))",
  "fr-FR": "French (France) (France francaise))",
  "fr-CH": "French (Switzerland) (Français (Suisse))",
  "de-AT": "German (Austria) (Deutsch (Österreich))",
  "de-DE": "German (Germany) (Deutsches Deutschland))",
  "de-CH": "German (Switzerland) (Deutsch (Schweiz))",
  "el-GR": "Greek (Greece) (Ελληνικά (Ελλάδα))",
  "gu-IN": "Gujarati (Indian) (ગુજરાતી (ભારતીય))",
  "he-IL": "Hebrew (Israel) (עברית (ישראל))",
  "hi-IN": "Hindi (India) (हिंदी भारत))",
  "hu-HU": "Hungarian (Hungary) (magyar (Magyarország))",
  "is-IS": "Icelandic (Iceland) (Íslenska (Ísland))",
  "id-ID": "Indonesian (Indonesia) (Indonesia (Indonesia))",
  "ga-IE": "Irish (Ireland) (Gaeilge (Éire))",
  "it-IT": "Italian (Italy) (Italiano (Italia))",
  "ja-JP": "Japanese (Japan) (日本語（日本）)",
  "jv-ID": "Javanese (Indonesia) (Basa Jawa (Indonesia))",
  "kn-IN": "Kannada (India) (ಕನ್ನಡ (ಭಾರತ))",
  "km-KH": "Khmer (Cambodia) (ខ្មែរ (កម្ពុជា))",
  "ko-KR": "Korean (Korea) (한국어(한국))",
  "lo-LA": "Lao (Laos) (ລາວ (ລາວ))",
  "lv-LV": "Latvian (Latvia) (latviešu (Latvija))",
  "lt-LT": "Lithuanian (Lithuania) (lietuvių (Lietuva))",
  "mk-MK": "Macedonian (North Macedonia) (македонски (Северна Македонија))",
  "MS": "Malay (Malaysia) (Melayu (Malaysia))",
  "mt-MT": "Maltese (Malta) (Malti (Malta))",
  "mr-IN": "Marathi (India) (मराठी (भारत))",
  "nb-NO": "Norwegian (Bokmål, Norway) (Norsk (bokmål, Norge))",
  "fa-IR": "Persian (Iran) (فارسی (ایران))",
  "pl-PL": "Polish (Poland) (polski (Polska))",
  "pt-BR": "Portuguese (Brazil) (Português (Brasil))",
  "pt-PT": "Portuguese (Portugal) (Português (Portugal))",
  "ro-RO": "Romanian (Romania) (romana (Romania))",
  "ru-RU": "Russian (Russia) (Русский (Россия))",
  "sr-RS": "Serbian (Serbia) (српски (Србија))",
  "si-LK": "Sinhala (Sri Lanka) (සිංහල (ශ්‍රී ලංකාව))",
  "sk-SK": "Slovak (Slovakia) (slovenčina (Slovensko))",
  "sl-SI": "Slovenian (Slovenia) (Slovenščina (Slovenija))",
  "es-AR": "Spanish (Argentina) (Español (Argentina))",
  "es-BO": "Spanish (Bolivia) (Español (Bolivia))",
  "es-CL": "Spanish (Chile) (Español (Chile))",
  "es-CO": "Spanish (Colombia) (Español (Colombia))",
  "es-CR": "Spanish (Costa Rica) (Español (Costa Rica))",
  "es-CU": "Spanish (Cuba) (Español (Cuba))",
  "es-DO": "Spanish (Dominican Republic) (Español (República Dominicana))",
  "es-EC": "Spanish (Ecuador) (Español (Ecuador))",
  "es-SV": "Spanish (El Salvador) (Español (El Salvador))",
  "es-GQ": "Spanish (Equatorial Guinea) (Español (Guinea Ecuatorial))",
  "es-GT": "Spanish (Guatemala) (Español (Guatemala))",
  "es-HN": "Spanish (Honduras) (Español (Honduras))",
  "es-MX": "Spanish (Mexico) (Español (México))",
  "es-NI": "Spanish (Nicaragua) (Español (Nicaragua))",
  "es-PA": "Spanish (Panama) (Español (Panamá))",
  "es-PY": "Spanish (Paraguay) (Español (Paraguay))",
  "es-PE": "Spanish (Peru) (Español (Perú))",
  "es-PR": "Spanish (Puerto Rico) (Español (Puerto Rico))",
  "es-ES": "Spanish (Spain) (Español (España))",
  "es-UY": "Spanish (Uruguay) (Español (Uruguay))",
  "es-US": "Spanish (USA) (Español (EE. UU.))",
  "es-VE": "Spanish (Venezuela) (Español (Venezuela))",
  "sw-KE": "Swahili (Kenya) (Kiswahili (Kenya))",
  "sw-TZ": "Swahili (Tanzania) (Kiswahili (Tanzania))",
  "sv-SE": "Swedish (Sweden) (svenska (Sverige))",
  "TA": "Tamil (India) (தமிழ் (இந்தியா))",
  "TE": "Telugu (India) (తెలుగు (భారతదేశం))",
  "th-TH": "Thai (Thailand) (ไทย (ประเทศไทย))",
  "tr-TR": "Turkish (Turkey) (Türkçe (Türkiye))",
  "uk-UA": "Ukrainian (Ukraine) (Українська (Україна))",
  "UR": "Urdu (اردو)",
  "uz-UZ": "Uzbek (Uzbekistan) (O'zbek (O'zbekiston))",
  "vi-VN": "Vietnamese (Vietnam) (Tiếng Việt (Việt Nam))",
  "zu-ZA": "Zulu (South Africa) (IsiZulu (Ningizimu Afrika))",
};
const languages = {
  PT: "Portuguese (Português)",
  EN: "English (English)",
  ES: "Spanish (español)",
  TR: "Turkish (Türkçe)",
  DE: "German (Deutsch)",
  IT: "Italian (Italiano)",
  AR: "Arabic (عربي)",
  FR: "French (Français)",
  ID: "Indonesian (bahasa Indonesia)",
  KO: "Korean (한국인)",
  JA: "Japanese (日本)",
  ZH: "Chinese (中国人)",
  HI: "Hindi (हिंदी)",
  RU: "Russian (Русский)",
  TH: "Thai (แบบไทย)",
  VI: "Vietnamese (Tiếng Việt)",
  "af-ZA": "Afrikaans (South Africa) (Afrikaans (Suid-Afrika))",
  "am-ET": "Amharic (Ethiopia) (አማርኛ (ኢትዮጵያ))",
  "ar-DZ": "Arabic (Algeria) (العربية (الجزائر))",
  "ar-BH":
    "Arabic (Bahrain), modern standard (العربية (البحرين) ، المعيار الحديث)",
  "ar-EG": "Arabic (Egypt) (العربية (مصر))",
  "ar-IQ": "Arabic (Iraq) (العربية (العراق))",
  "ar-IL": "Arabic (Israel) (العربية (إسرائيل))",
  "ar-JO": "Arabic (Jordan) (العربية (الأردن))",
  "ar-KW": "Arabic (Kuwait) (العربية (الكويت))",
  "ar-LB": "Arabic (Lebanon) (العربية (لبنان))",
  "ar-LY": "Arabic (Libya) (العربية (ليبيا))",
  "ar-MA": "Arabic (Morocco) (العربية (المغرب))",
  "ar-OM": "Arabic (Oman) (العربية (عمان))",
  "ar-PS": "Arabic (Palestinian Authority) (العربية (السلطة الفلسطينية))",
  "ar-QA": "Arabic (Qatar) (العربية (قطر))",
  "ar-SA": "Arabic (Saudi Arabia) (العربية (المملكة العربية السعودية))",
  "ar-SY": "Arabic (Syria) (العربية (سوريا))",
  "ar-TN": "Arabic (Tunisia) (العربية (تونس))",
  "ar-AE": "Arabic (United Arab Emirates) (العربية (الإمارات العربية المتحدة))",
  "ar-YE": "Arabic (Yemen) (العربية (اليمن))",
  BN: "Bengali (বাংলা)",
  "bg-BG": "Bulgarian (Bulgaria) (български (България))",
  MY: "Burmese (Myanmar) (ဗမာ (မြန်မာ))",
  "ca-ES": "Catalan (Spain) (català (Espanya))",
  "zh-HK": "Chinese (Cantonese, Traditional) (中文（粤语、繁体）)",
  "zh-CN": "Chinese (Mandarin, Simplified) (中文（普通话、简体）)",
  "zh-TW": "Chinese (Taiwanese Mandarin) (中文（台湾普通话）)",
  "hr-HR": "Croatian (Croatia) (hrvatski (Hrvatska))",
  "cs-CZ": "Czech (Czech) (český (český))",
  "da-DK": "Danish (Denmark) (Dansk (Danmark))",
  "nl-BE": "Dutch (Belgium) (Nederlands (België))",
  "nl-NL": "Dutch (Netherlands) (Nederlands (Nederland))",
  "en-AU": "English (Australia) ",
  "en-CA": "English (Canada) ",
  "en-GH": "English (Ghana) ",
  "en-HK": "English (Hong Kong) ",
  "en-IN": "English (India) ",
  "en-IE": "English (Ireland)",
  "en-KE": "English (Kenya) ",
  "en-NZ": "English (New Zealand) ",
  "en-NG": "English (Nigeria) ",
  "en-PH": "English (Philippines)",
  "en-SG": "English (Singapore) ",
  "en-ZA": "English (South Africa) ",
  "en-TZ": "English (Tanzania) ",
  "en-GB": "English (United Kingdom)",
  "en-US": "English (United States) ",
  "et-EE": "Estonian (Estonia) (eesti keel (Eesti))",
  "fil-PH": "Filipino (Philippines) (filippiiniläinen (Filippiinit))",
  FI: "Finnish (Finland) (suomi (Suomi))",
  "fr-BE": "French (Belgium) (Français (Belgique))",
  "fr-CA": "French (Canada) (Français (Canada))",
  "fr-FR": "French (France) (France francaise))",
  "fr-CH": "French (Switzerland) (Français (Suisse))",
  "de-AT": "German (Austria) (Deutsch (Österreich))",
  "de-DE": "German (Germany) (Deutsches Deutschland))",
  "de-CH": "German (Switzerland) (Deutsch (Schweiz))",
  "el-GR": "Greek (Greece) (Ελληνικά (Ελλάδα))",
  "gu-IN": "Gujarati (Indian) (ગુજરાતી (ભારતીય))",
  "he-IL": "Hebrew (Israel) (עברית (ישראל))",
  "hi-IN": "Hindi (India) (हिंदी भारत))",
  "hu-HU": "Hungarian (Hungary) (magyar (Magyarország))",
  "is-IS": "Icelandic (Iceland) (Íslenska (Ísland))",
  "id-ID": "Indonesian (Indonesia) (Indonesia (Indonesia))",
  "ga-IE": "Irish (Ireland) (Gaeilge (Éire))",
  "it-IT": "Italian (Italy) (Italiano (Italia))",
  "ja-JP": "Japanese (Japan) (日本語（日本）)",
  "jv-ID": "Javanese (Indonesia) (Basa Jawa (Indonesia))",
  "kn-IN": "Kannada (India) (ಕನ್ನಡ (ಭಾರತ))",
  "km-KH": "Khmer (Cambodia) (ខ្មែរ (កម្ពុជា))",
  "ko-KR": "Korean (Korea) (한국어(한국))",
  "lo-LA": "Lao (Laos) (ລາວ (ລາວ))",
  "lv-LV": "Latvian (Latvia) (latviešu (Latvija))",
  "lt-LT": "Lithuanian (Lithuania) (lietuvių (Lietuva))",
  "mk-MK": "Macedonian (North Macedonia) (македонски (Северна Македонија))",
  MS: "Malay (Malaysia) (Melayu (Malaysia))",
  "mt-MT": "Maltese (Malta) (Malti (Malta))",
  "mr-IN": "Marathi (India) (मराठी (भारत))",
  "nb-NO": "Norwegian (Bokmål, Norway) (Norsk (bokmål, Norge))",
  "fa-IR": "Persian (Iran) (فارسی (ایران))",
  "pl-PL": "Polish (Poland) (polski (Polska))",
  "pt-BR": "Portuguese (Brazil) (Português (Brasil))",
  "pt-PT": "Portuguese (Portugal) (Português (Portugal))",
  "ro-RO": "Romanian (Romania) (romana (Romania))",
  "ru-RU": "Russian (Russia) (Русский (Россия))",
  "sr-RS": "Serbian (Serbia) (српски (Србија))",
  "si-LK": "Sinhala (Sri Lanka) (සිංහල (ශ්‍රී ලංකාව))",
  "sk-SK": "Slovak (Slovakia) (slovenčina (Slovensko))",
  "sl-SI": "Slovenian (Slovenia) (Slovenščina (Slovenija))",
  "es-AR": "Spanish (Argentina) (Español (Argentina))",
  "es-BO": "Spanish (Bolivia) (Español (Bolivia))",
  "es-CL": "Spanish (Chile) (Español (Chile))",
  "es-CO": "Spanish (Colombia) (Español (Colombia))",
  "es-CR": "Spanish (Costa Rica) (Español (Costa Rica))",
  "es-CU": "Spanish (Cuba) (Español (Cuba))",
  "es-DO": "Spanish (Dominican Republic) (Español (República Dominicana))",
  "es-EC": "Spanish (Ecuador) (Español (Ecuador))",
  "es-SV": "Spanish (El Salvador) (Español (El Salvador))",
  "es-GQ": "Spanish (Equatorial Guinea) (Español (Guinea Ecuatorial))",
  "es-GT": "Spanish (Guatemala) (Español (Guatemala))",
  "es-HN": "Spanish (Honduras) (Español (Honduras))",
  "es-MX": "Spanish (Mexico) (Español (México))",
  "es-NI": "Spanish (Nicaragua) (Español (Nicaragua))",
  "es-PA": "Spanish (Panama) (Español (Panamá))",
  "es-PY": "Spanish (Paraguay) (Español (Paraguay))",
  "es-PE": "Spanish (Peru) (Español (Perú))",
  "es-PR": "Spanish (Puerto Rico) (Español (Puerto Rico))",
  "es-ES": "Spanish (Spain) (Español (España))",
  "es-UY": "Spanish (Uruguay) (Español (Uruguay))",
  "es-US": "Spanish (USA) (Español (EE. UU.))",
  "es-VE": "Spanish (Venezuela) (Español (Venezuela))",
  "sw-KE": "Swahili (Kenya) (Kiswahili (Kenya))",
  "sw-TZ": "Swahili (Tanzania) (Kiswahili (Tanzania))",
  "sv-SE": "Swedish (Sweden) (svenska (Sverige))",
  TA: "Tamil (India) (தமிழ் (இந்தியா))",
  TE: "Telugu (India) (తెలుగు (భారతదేశం))",
  "th-TH": "Thai (Thailand) (ไทย (ประเทศไทย))",
  "tr-TR": "Turkish (Turkey) (Türkçe (Türkiye))",
  "uk-UA": "Ukrainian (Ukraine) (Українська (Україна))",
  UR: "Urdu (اردو)",
  "uz-UZ": "Uzbek (Uzbekistan) (O'zbek (O'zbekiston))",
  "vi-VN": "Vietnamese (Vietnam) (Tiếng Việt (Việt Nam))",
  "zu-ZA": "Zulu (South Africa) (IsiZulu (Ningizimu Afrika))",
};
