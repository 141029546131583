import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useModal } from "../../components/common/Modal/ModalContext";
import { showToast } from "../../components/common/toast";
import { fetchData } from "../../hooks/apiUtils";
import { useAuth } from "../../Auth/AuthContext";
import { useTranslation } from "../../components/common/LanguageProvider";
function Download({content, filename, audio, contentRefs}){
    const { t } = useTranslation();
    const {showModal} = useModal();
    const { hashedId } = useAuth();
    const getText = () => {
        console.log(content[0])
        console.log("xxx")
        return content.map((item, index) => {
            const innerHTMLValue = contentRefs.current && contentRefs.current[index]
                ? contentRefs.current[index].innerText.replaceAll("#", "")
                : null;
            return {
                ...item,
                text: innerHTMLValue || item.text
            };
        }).filter(item => item.text && item.text.trim() !== "");
    };
    
    var items = []
    
    const handleDownloadTranscript = (exportFormat, paragraphSize, includeTimestamps, mergeSegments, includeSpeakerNames, singleParagraph, transcription, mp3) => {
        if(mp3){
            var link = audio;
            window.open(link, '_blank');
        }
        console.log(paragraphSize)
        console.log("kek")
        if(transcription){
            if(paragraphSize > 1 && exportFormat != "srt"){
                mergeSpeakers(1,paragraphSize)
            }
            if(mergeSegments && exportFormat !== "srt"){
                mergeSpeakers(0,  4);
            }
            createText(exportFormat, includeSpeakerNames, includeTimestamps, singleParagraph)
        }

    };
    var type = "\n"
    const download = () => {
        const texts = items || getText();
        const combinedText = texts.map(textArea => textArea.text.replaceAll("#", "")).join(type);
        return combinedText;
    }
    
    const downloadmono = () => {
        const texts = items || getText();

        const combinedText = texts.map(textArea => textArea.text.replaceAll("#", "")).join(' ');
        return combinedText;
    }
    
    const downloadss = () => {
        const texts = items || getText();

        let combinedText = texts.map(item => {
            let text = item.Speaker ? item.Speaker + type : ''; // Append speaker if it exists
            text += item.text.replaceAll("#", "") + type + type; // Append the actual text and two newlines
            return text;
        }).join('');
        return combinedText;
    }
    const downloadts = () => {
        const texts = items || getText();

        let combinedText = texts.map(item => {
            let text = item.VoiceStart || item.VoiceStart === 0 ? msToTime(item.VoiceStart) + type : ''; // Append speaker if it exists
            text += item.text.replaceAll("#", "") + type + type; // Append the actual text and two newlines
            return text;
        }).join('');
        return combinedText;
    }
    const downloadtsss = () => {
        const texts = items || getText();

        let combinedText = texts.map(item => {
            let text = (item.VoiceStart || item.VoiceStart === 0) && item.Speaker ? msToTime(item.VoiceStart) + " " + item.Speaker + type : ''; // Append speaker if it exists
            text += item.text.replaceAll("#", "") + type + type; // Append the actual text and two newlines
            return text;
        }).join('');
        return combinedText;
    }
    
    const downloadsrt = () => {
        const items = getText();
    
        const srtText = items.map((item, index) => {
            let text = (index + 1).toString() + type;
            let voiceEnd = item.VoiceEnd;
        
            // Convert to formatted time for comparison
            let formattedVoiceStart = submsToTime(item.VoiceStart);
            let formattedVoiceEnd = submsToTime(voiceEnd);
    
            // Debugging statements
            console.log("Current Index:", index);
            console.log("VoiceStart:", formattedVoiceStart);
            console.log("Original VoiceEnd:", formattedVoiceEnd);
        
            // Check if formattedVoiceEnd is smaller than formattedVoiceStart
            if (formattedVoiceEnd < formattedVoiceStart && items[index + 1]) {
                voiceEnd = items[index + 1].VoiceStart;
                formattedVoiceEnd = submsToTime(voiceEnd); // Update the formattedVoiceEnd
                console.log("Updated VoiceEnd:", formattedVoiceEnd); // Debugging statement
            }
        
            text += (item.VoiceStart || item.VoiceStart === 0) && voiceEnd ? formattedVoiceStart + " --> " + formattedVoiceEnd + type : '';
            text += item.text.replaceAll("#", "") + type + type;
            return text;
        }).join('');
        
        return srtText;
    }
    
    const createText = async (exportFormat, includeSpeakerNames, includeTimestamps, singleParagraph) => {
        var copiedText = "";
        if(exportFormat === "word")
            type = "<br>"
        if(exportFormat === "srt"){
            copiedText = downloadsrt();
        }else{
            if(singleParagraph){
                copiedText = downloadmono();
            } else if (includeSpeakerNames && includeTimestamps) {
                copiedText = downloadtsss();
            } else if (includeSpeakerNames) {
                copiedText = downloadss();
            } else if (includeTimestamps) {
                copiedText = downloadts();
            } else {
                copiedText = download();
            }
        }    

        if (exportFormat === "txt") {
            const link = document.createElement('a');
            link.setAttribute('download', filename + '.txt');
            link.setAttribute('href', 'data:' + 'text/plain' + ';charset=utf-8,' + encodeURIComponent(copiedText) + '\n');
            link.click();
        }else if(exportFormat === "word"){
            var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
            var postHtml = "</body></html>";
            var html = preHtml + copiedText + postHtml;
            var blob = new Blob(['\ufeff', html], {type: 'application/msword'});
            // Specify link url
            var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
            // Specify file name
            filename = filename + '.doc'
            // Create download link element
            var downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            if(navigator.msSaveOrOpenBlob ){
                navigator.msSaveOrOpenBlob(blob, filename);
            }else{
                // Create a link to the file
                downloadLink.href = url;
                // Setting the file name
                downloadLink.download = filename;
                //triggering the function
                downloadLink.click();
            }
            document.body.removeChild(downloadLink);
        }else if(exportFormat === "srt"){
            var link = document.createElement('a');
            link.setAttribute('download', filename + '.srt');
            link.setAttribute('href', 'data:' + 'text/plain' + ';charset=utf-8,' + encodeURIComponent(copiedText) + '\n');
            link.click();
        }
        else if(exportFormat === "clipboard"){
            try {
                await navigator.clipboard.writeText(copiedText);
                showToast(t("The text copied to clipboard!"), 'success');
                return true;
            } catch (err) {
                console.error('Failed to copy text: ', err);
                return false;
            }
        }
        
    };

    const mergeSpeakers = (current, limit) => {
        const mergedItems = [];
        
        let i = 0;
        let temp_current = current;
        while (i < items.length) {
            let mergedItem = { ...items[i] };
            
            // Merge adjacent items with the same speaker
            while (i < items.length - 1 && items[i].Speaker === items[i + 1].Speaker && limit > temp_current) {
                temp_current++;
                mergedItem.text += " " + items[i + 1].text;
                mergedItem.VoiceEnd = items[i + 1].VoiceEnd;
                i++;
            }
            temp_current = current;
            mergedItems.push(mergedItem);
            i++;    
        }
        
        // Update state with mergedItems
        // Assuming you have a function or state to update the modified items, 
        // if not you will need to create one.
       items = mergedItems;
    };
    

    
    function msToTime(duration) {
        console.log(duration)
        console.log("duration")
        if(duration === 0)
            return "00:00:00"
        var milliseconds = (duration % 1000);
        var seconds = Math.floor(duration / 1000) % 60;
        var minutes = Math.floor(duration / (1000 * 60)) % 60;
        var hours = Math.floor(duration / (1000 * 60 * 60)) % 24;
        if (hours < 10) {
            hours = "0" + hours.toString()
        }
        if (minutes < 10) {
            minutes = "0" + minutes.toString()
        }
        if (seconds < 10) {
            seconds = "0" + seconds.toString()
        }
        return hours.toString() + ":" + minutes.toString() + ":" + seconds.toString()
    }
    
function submsToTime(duration){
    if(duration === 0)
    return "00:00:00"
    var milliseconds = Math.floor(duration%1000)
    var seconds = Math.floor(duration/1000)%60
    var minutes = Math.floor(duration/(1000*60))%60
    var hours = Math.floor(duration/(1000*60*60))%24
    if (hours < 10){
        hours = "0" + hours.toString()}
    if (minutes < 10){
        minutes = "0" + minutes.toString()}
    if (seconds < 10){
        seconds = "0" + seconds.toString()}
    var strmilliseconds = milliseconds.toString()
    if (strmilliseconds.length==1){
        strmilliseconds = strmilliseconds + "00"}
    else if(strmilliseconds.length==2){
        strmilliseconds = strmilliseconds + "0"}
    return hours.toString() + ":" + minutes.toString() + ":" + seconds.toString() + "," + strmilliseconds

}

    const checkUser = async() => {
        const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
        if(response.SStatus === "Trial" ){
            showModal("UPGRADE-DOWNLOAD");
        }else{
            items = getText();
            showModal("DOWNLOAD-TRANSCRIPT", {onDownloadTranscript: handleDownloadTranscript})
        }
    }
    return(
        <>
                 <Link
                        onClick={(e)=> {
                            e.preventDefault();
                            checkUser()
                        }}
                        className="hover-effect"
                      >
                        <span
                          class="file-action-list theme-color"
                         
                        >
                             <span className="hover-span">
                          {t("Download")}
                          </span> 
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="19.34"
                            viewBox="0 0 21 19.34"
                          >
                            <g
                              id="Group_46"
                              data-name="Group 46"
                              transform="translate(-0.16)"
                            >
                              <rect
                                id="Rectangle_36"
                                data-name="Rectangle 36"
                                width="21"
                                height="15"
                                rx="3"
                                transform="translate(21.16 15.34) rotate(180)"
                                fill="#fff"
                              />
                              <rect
                                id="Rectangle_37"
                                data-name="Rectangle 37"
                                width="21"
                                height="5"
                                rx="2.5"
                                transform="translate(21.16 17.34) rotate(180)"
                                fill="currentColor"
                              />
                              <rect
                                id="Rectangle_38"
                                data-name="Rectangle 38"
                                width="21"
                                height="5"
                                rx="2.5"
                                transform="translate(21.16 19.34) rotate(180)"
                                fill="#fff"
                              />
                              <g
                                id="arrow-small-down"
                                transform="translate(5.402)"
                              >
                                <path
                                  id="Path_163"
                                  data-name="Path 163"
                                  d="M21.767,18.641l-4.612,4.613H15.862L11.25,18.641l1.292-1.31,3.044,3.026V15H17.43v5.357l3.045-3.045Z"
                                  transform="translate(-11.25 -15)"
                                  fill="currentColor"
                                />
                              </g>
                            </g>
                          </svg>
                          
                        </span>
                      </Link>
        </>
    );

}

export default Download;