import React, { useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthContext';


import recordPng from "../assets/img/icon/record-btn-icon.svg"
import appSumo from "../assets/img/icon/appsumo.svg"

import { useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../components/common/toast";
import { useTranslation } from '../components/common/LanguageProvider';
import { set } from 'react-hook-form';
function DiscountView() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [discountCode, setDiscountCode] = useState("");
    const [discountedPlan, setDiscountedPlan] = useState(0);
    const { hashedId } = useAuth();
    const [userStatus, setUserStatus] = useState(null);
    const [codeInProgress, setCodeInProgress] = useState(false);
    const [routeLoading, setRouteLoading] = useState(true);
    const [redirecting, setRedirecting] = useState(false)
    const isChrome = window.navigator.userAgent.includes("Chrome")
    useEffect(() => {
        if (hashedId)
            checkUserStatus();
    }, [hashedId]);

    const handleInputChange = (event) => {
        setDiscountCode(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

    };


    const checkUserStatus = async () => {
        try {
            setRouteLoading(true);
            const res = await fetch("https://k5qrttp0r1.execute-api.eu-central-1.amazonaws.com/default/AppSumo-CheckUserStatus?uid=" + hashedId)
            const data = await res.json();
           setUserStatus(data);
            console.log(data);
            setRouteLoading(false);
        } catch (error) {
            setRouteLoading(false);
            console.log(error);
        }

    }

    const applyCode = async () => {
        try {
            setCodeInProgress(true);
            const res = await fetch("https://x9ubks3hd0.execute-api.eu-central-1.amazonaws.com/default/AppSumo-ApplyCode?uid=" + hashedId + "&code=" + discountCode)
            const data = await res.json();
            if (data.is_success) {
                checkUserStatus();
            } else {
                showToast(t("Code is invalid"), "error");
            }
            setCodeInProgress(false);

        } catch (error) {
            console.log(error);
        }
    }

    const redirectCheckout = async (plan) => {
        if (getButtonText(plan) === "Current Plan") {
            return;
        }
        setRedirecting(true);
        const res = await fetch("https://1cakhnp24b.execute-api.eu-central-1.amazonaws.com/default/Subscription-AppSumoCheckoutCreator?uid=" + hashedId + "&plan=" + plan)
        const data = await res.json();
        if (data.url) {
            window.location.href = data.url
        } else {
            showToast("Error redirecting to checkout", "error");
        }
        setRedirecting(false);

    }

    const getButtonText = (plan) => {
        const currentPlan = userStatus.current_plan;
        if (currentPlan !== 'lite' && currentPlan !== 'premium' && currentPlan !== 'premium-yearly') {
            return "Upgrade";
        } else if (plan === currentPlan) {
            return "Current Plan";
        } else if (plan === 'lite') {
            return "Downgrade";
        } else if (plan === 'premium-yearly') {
            return "Upgrade";
        } else if (plan === 'premium') {
            return currentPlan === 'premium-yearly' ? "Downgrade" : "Upgrade";
        }
    }

    return (
        <>
            {redirecting &&
                <div class='d-flex justify-content-center'>
                    <div className="spinner-border text-primary mb-2" role="status"> </div>
                </div>
            }

            {routeLoading || !userStatus ?
                <div class='d-flex justify-content-center'>
                    <div className="spinner-border text-primary mt-4" role="status"> </div>
                </div>
                :
                !userStatus.is_qualified ?

                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-6 offset-md-3">

                                <div class="  card-margin" >
                                    <div class="card card-100 flex-center card-padding justify-content-between" >

                                        <p class="ft-18-title mb-2">{t("Apply Discount Code")}</p>

                                        <div class="form-group input-icon-div w-100 text-center mb-0">
                                            <p>{t("Enter the discount code")}</p>
                                            <input onChange={handleInputChange} type="text" class="form-control round-input" placeholder={`${t("Write code here...")}`} name="" value={discountCode} />

                                        </div>
                                        <div class="text-center mb-4">{
                                            codeInProgress ? <div class="spinner-border text-primary mt-3" role="status"> </div> :
                                                <button onClick={applyCode} class="btn theme-btn mt-3"
                                                    disabled={discountCode === '' ? true : null}
                                                >{t("Apply")}</button>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    : <div className='row justify-content-center '>

                        <div class="col-xxl-4 col-lg-5 col-md-6 col-12 card-margin">
                            <div class="card text-center card-100 payment-card"
                                data-value="Lite"
                                onClick={(e) => {
                                    redirectCheckout('lite');
                                }}
                            >
                                <div class="theme-bg card-padding white-color">
                                    <div className='d-flex justify-content-center'>
                                        <h2 class="package-price mt-2 mb-1 ">$0</h2>
                                    </div>
                                </div>
                                <div class="card-padding">
                                    <h3 class="medium-title">{t("Lite")}</h3>
                                    <p className='mb-0 ft-18 payment-bold'>{t("300 AI Credits")}</p>
                                    <p class="mb-0">{t("Usage reset every month")}</p>
                                    <p class="mb-0 dark-color mt-2">✓ {t("30 Gb Cloud Storage")}</p>
                                    <p class="mb-0 dark-color">✓ {t("300 AI credits (Transcription, Content Writing, AI Chat)")}</p>
                                    <p class="mb-0  dark-color">✓ {t("Unlimited Screen Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Video Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Audio Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Video Sharings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Webcam Recordings")}</p>
                                

                                    {getButtonText('lite') === "Current Plan"
                                        ?
                                        <p class="dark-color mt-5" style={{ textDecoration: 'underline', fontSize: '28px' }}>Current Plan</p>
                                        : <button class="btn theme-btn my-4 payment-btn" data-value="Lite"
                                            onClick={(e) => {
                                                redirectCheckout('lite');
                                            }}

                                        >{t(getButtonText('lite')).toUpperCase()}
                                            <span class="ms-2">
                                                {false &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                                    </svg>
                                                }
                                            </span>
                                        </button>}

                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-lg-5 col-md-6 col-12 card-margin">
                            <div class="card text-center card-100  payment-card"
                                data-value="Premium"
                                onClick={(e) => {

                                    redirectCheckout('premium');
                                }}
                            >
                                <div class="theme-bg card-padding white-color">
                                    <div class="mt-2 mb-3">
                                        <span style={{ textDecoration: 'line-through', fontSize: '28px' }}>$24.99</span>
                                        <h2 class="package-price" style={{ display: 'inline', marginLeft: '8px' }}>$14.99</h2>
                                    </div>
                                </div>
                                <div class="card-padding">
                                    <h3 class="medium-title">Premium</h3>
                                    <p className='mb-0 ft-18 payment-bold'>{t("2400 AI Credits")}</p>
                                    <p class="mb-0">{t("Usage reset every month")}</p>
                                    <p class="mb-0 dark-color mt-2">✓ {t("30 Gb Cloud Storage")}</p>
                                    <p class="mb-0 dark-color">✓ {t("2400 AI credits (Transcription, Content Writing, AI Chat) every month")}</p>
                                    <p class="mb-0  dark-color">✓ {t("Unlimited Screen Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Video Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Audio Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Video Sharings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Webcam Recordings")}</p>
                                    {getButtonText('premium') === "Current Plan"
                                        ?
                                        <p class="dark-color mt-5" style={{ textDecoration: 'underline', fontSize: '28px' }}>Current Plan</p>
                                        : <button class="btn theme-btn my-4 payment-btn" data-value="Lite"
                                            onClick={(e) => {

                                                redirectCheckout('premium');
                                            }}

                                        >{t(getButtonText('premium')).toUpperCase()}
                                            <span class="ms-2">
                                                {false &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                                    </svg>
                                                }
                                            </span>
                                        </button>}


                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-lg-5 col-md-6 col-12 card-margin">
                            <div class="card text-center card-100  payment-card"
                                data-value="Premium-Annual"
                                onClick={(e) => {

                                    redirectCheckout('premium-yearly');
                                }}
                            >
                                <div class="theme-bg card-padding white-color">

                                    <div class="mt-2 mb-3">
                                        <span style={{ textDecoration: 'line-through', fontSize: '28px' }}>$149.99</span>
                                        <h2 class="package-price" style={{ display: 'inline', marginLeft: '8px' }}>$89.99</h2>
                                    </div>
                                    <p class="ft-14-title mb-4 align-self-end d-none">${t("6.66/month")}</p>
                                </div>
                                <div class="card-padding">
                                    <h3 class="medium-title">Premium Annual</h3>
                                    <p className='mb-0 ft-18 payment-bold'>{t("2400 AI Credits per month")}</p>
                                    <p class="mb-0">{t("Usage reset every month, billed once per year")}</p>
                                    <p class="mb-0 dark-color mt-2">✓ {t("30 Gb Cloud Storage")}</p>
                                    <p class="mb-0 dark-color">✓ {t("2400 AI credits (Transcription, Content Writing, AI Chat) every month")}</p>
                                    <p class="mb-0  dark-color">✓ {t("Unlimited Screen Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Video Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Audio Recordings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Video Sharings")}</p>
                                    <p class="mb-0 dark-color">✓ {t("Unlimited Webcam Recordings")}</p>

                                    {getButtonText('premium-yearly') === "Current Plan"
                                        ?
                                        <p class="dark-color mt-5" style={{ textDecoration: 'underline', fontSize: '28px' }}>Current Plan</p>
                                        : <button class="btn theme-btn my-4 payment-btn" data-value="Lite"
                                            onClick={(e) => {

                                                redirectCheckout('premium-yearly');
                                            }}

                                        >{t(getButtonText('premium-yearly')).toUpperCase()}
                                            <span class="ms-2">
                                                {false &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                                    </svg>
                                                }
                                            </span>
                                        </button>}

                                </div>
                            </div>
                        </div>
                    </div>}
        </>
    );
}

export default DiscountView;



