import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../Auth/AuthContext';
import { fetchData, fetchDataPost } from '../../hooks/apiUtils';
import FileItem from './FileItem';
import { useApiData } from '../../hooks/useApiData';
import { Link, useParams, useNavigate } from 'react-router-dom';
import moveWhite from '../../assets/img/icon/move-white.svg';
import shareWhite from '../../assets/img/icon/share-white.svg';
import deleteWhite from '../../assets/img/icon/delete-white.svg';
import checkAllWhite from '../../assets/img/icon/check-all-white.svg';
import sharePeopleSvg from '../../assets/img/icon/share-people.svg';
import sharePeopleSvg2 from '../../assets/img/icon/share-people2.svg';

import closeSvg from '../../assets/img/icon/close.svg';
import { showToast } from '../../components/common/toast';
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg"
import renameSvg from "../../assets/img/icon/rename.svg"
import deleteSvg from "../../assets/img/icon/delete.svg"
import recordPng from "../../assets/img/icon/record-btn-icon.svg"
import botPng from "../../assets/img/icon/minute-btn-icon.svg"
import { useModal } from '../../components/common/Modal/ModalContext';
import { Modal } from 'react-bootstrap';
import { useUploader } from '../Uploader/UploaderContext';
import { useTranslation } from '../../components/common/LanguageProvider';
import { popularLanguages, allLanguages } from '../../components/common/transcribeLanguageDropdown';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function Files(props) {
    const { fid, uid, tid } = useParams();
    const { hashedId, userData } = useAuth();
    const { showModal } = useModal();
    const [userStatus, setUserStatus] = useState(null);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [allFiles, setAllFiles] = useState([]);
    const [filesToShow, setFilesToShow] = useState([]);
    const [folders, setFolders] = useState([]);
    const [teams, setTeams] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filesLength, setFilesLength] = useState(0);
    const [checkedItems, setCheckedItems] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [folderName, setFolderName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const isFile = props.isFile || false;
    const isFolder = props.isFolder || false;
    const filesPerPage = isFile ? 10 : 5;
    const [showProcessingModal, setShowProcessingModal] = useState(false);
    const [processingRecordingLoading, setProcessingRecordingLoading] = useState(true);

    const { filesRouteLoading } = useUploader();

    var startIndex = 0;
    var endIndex = 0;
    const fetchFiles = async () => {
        setIsLoading(true); // Set loading to true at the beginning of data fetching

        var url = "https://0koqxthb4m.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFiles?cid=" + hashedId + "&app=Recorditor";
        if (fid !== undefined) {
            url = " https://0koqxthb4m.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFiles?cid=" + fid + "&app=Recorditor";
        }
        if (tid !== undefined) {
            var url = "https://0koqxthb4m.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFiles?cid=" + tid + "&app=Recorditor";

        }

        // url = " https://0koqxthb4m.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFiles?cid=" + "a7e2077e53af7982eb56ddb5cb0edaa793be75cdd14640026cf47bc4661658992ef56f40bbfd475e519cb708990a25140f40685a4f0143e259e80a32b898482a" + "&app=Recorditor";
        const data = await fetchData(url);
        data.sort((a, b) => {
            return parseInt(b.Date?.N) - parseInt(a.Date?.N);
        });
        setAllFiles(data);
        setFilesLength(data.length);
        setIsLoading(false); // Set loading to false once data fetching is complete
    }

    const fetchFolders = async () => {
        let tempId = tid ? tid : hashedId;
        var data = await fetchData("https://i3w4h1tgb5.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFolders?cid=" + tempId);
        if (fid !== undefined) {
            data.forEach(element => {
                if (element.SK.S.replace("User#", "") === fid) {
                    setFolderName(element.Fname.S)
                }
            });
        }
        data = data.filter(element => element.SK.S.replace("User#", "") !== fid);
        setFolders(data);
    };
    const fetchTeams = async () => {
        var data = await fetchData("https://5eozpazgv4.execute-api.eu-central-1.amazonaws.com/default/Get-Teams?HashedId=" + hashedId)
        if (tid !== undefined) {
            data = data.filter(element => element.TeamId !== tid);
        }

        setTeams(data)
    };
    const onProcessingFileClicked = async (tid) => {
        setShowProcessingModal(true);
        const res = await fetchFiles();
        if (res) {
            // check whether the file is still processing
            if (allFiles.filter(file => file?.SK?.S === "#Meetingtor#" + tid)[0]?.TStatus?.S === "Processing" || allFiles.filter(file => file?.SK?.S === "#Meetingtor#" + tid)[0]?.TStatus?.S === "in_call_recording") {
                setProcessingRecordingLoading(false);
            } else {
                setProcessingRecordingLoading(false);
                setShowProcessingModal(false);
                navigate(`/meeting/${tid}`);
            }
        }

    }

    useEffect(() => {
        if (userData)
            setUserStatus(userData.SStatus);
    }, [userData]);

    useEffect(() => {
        console.log(hashedId);
        console.log("in use effect")
        if (hashedId || tid || fid) {
            fetchFiles();
            fetchFolders();
            fetchTeams();
            fetchLanguages();
        }

    }, [hashedId]);

    const fetchLanguages = async () => {
        if (!hashedId) return;
        try {
            const queryParams = new URLSearchParams({
                user_id: hashedId,
                dashboard_language: localStorage.getItem("dashboard-language") || "en",
                app_name: 'recorditor'
            }).toString();
            const response = await fetch(`https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/getlanguages?${queryParams}`);

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem("user_locales", JSON.stringify(data.user_specific_stt_locales));
                localStorage.setItem("all_locales", JSON.stringify(data.stt_locales));
            } else {
                console.error('Failed to fetch languages:', response.statusText);
                localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
                localStorage.setItem("all_locales", JSON.stringify(allLanguages));
            }
        } catch (error) {
            console.error('Error fetching languages:', error);
            localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
            localStorage.setItem("all_locales", JSON.stringify(allLanguages));
        }
    };

    useEffect(() => {
        // Calculate the indexes of files to be displayed on the current page
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;

        // Update the files to show based on the new indexes
        setFilesToShow(allFiles.slice(startIndex, endIndex));

    }, [allFiles, currentPage]);
    useEffect(() => {
        console.log("Checked Items Updated:", checkedItems[0]);
    }, [checkedItems]);
    useEffect(() => {
        if (filesToShow.length > 0) {
            for (let file of filesToShow) {
                let date_flag = false;
                let myDate = new Date(parseInt(file?.Date?.N)) || Date.now(); // assuming file.Date.N is a Unix timestamp in milliseconds
                let currentDate = Date.now();
                let diffInMs = currentDate - myDate.getTime();

                if (diffInMs < (1000 * 60 * 60 * 24)) {
                    date_flag = true;
                }

                let status = file?.TStatus?.S || "Completed";
                const delayInMilliseconds = 120000;

                if (status === "Processing" && date_flag) {
                    setTimeout(fetchFiles, delayInMilliseconds);
                    break; // This will exit the loop if the conditions are met
                }
            }
        }
    }, [filesToShow]);

    const handleDelete = (tid, uid, multiple) => {
        // Implement delete functionality here
        // After deletion from the server, remove it from state
        fetchDataPost("https://pqj3gu2ill.execute-api.eu-central-1.amazonaws.com/default/Recorditor-DeleteOrder", { tid: "#Recorditor#" + tid, cid: "User#" + uid })
        console.log(tid);
        console.log(uid);
        setAllFiles((prevFiles) => prevFiles.filter(file => file.SK.S !== "#Recorditor#" + tid));
        setFilesLength((prevLength) => prevLength - 1);
        if (!multiple) {
            showToast(t("File deleted"), { type: "success" });
        }

    }

    const handleRename = (tid, uid, name) => {
        console.log(tid);
        console.log(uid);
        console.log(name);
        fetchDataPost("https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?", { tid: "#Recorditor#" + tid, cid: "User#" + uid, Tname: name })

        console.log(tid);
        console.log(uid);
        var updatedFiles = [...allFiles];
        updatedFiles = updatedFiles.map(file => {
            if (file.SK.S === "#Recorditor#" + tid) {
                file.TempName.S = name;
            }
            return file;
        }
        );
        setAllFiles(updatedFiles);
        showToast(t("File renamed"), { type: "success" });


        // Implement rename functionality here
        // After renaming on the server, update the name in state
    }

    const handleMove = (tid, uid, fid, isMultiple) => {
        // Implement move functionality here
        // Update state accordingly
        fetchDataPost("https://319zoan27h.execute-api.eu-central-1.amazonaws.com/default/AA-API-MoveFile", { pk: "User#" + uid, tid: "#Recorditor#" + tid, fid: fid })

        console.log(tid);
        console.log(uid);

        setAllFiles((prevFiles) => prevFiles.filter(file => file.SK.S !== "#Recorditor#" + tid));
        setFilesLength((prevLength) => prevLength - 1);
        if (!isMultiple) {
            showToast(t("File moved to folder"), { type: "success" });
        }
    }
    const handleShareTeam = (tid, uid, teamId, multiple) => {
        console.log(tid);
        console.log(uid);
        console.log(teamId);
        fetchDataPost(
            "https://po0s8vut8h.execute-api.eu-central-1.amazonaws.com/default/AA-API-MoveFileToTeam?",
            { pk: "User#" + uid, sk: "#Recorditor#" + tid, TeamId: teamId })

        setAllFiles((prevFiles) => prevFiles.filter(file => file.SK.S !== "#Recorditor#" + tid));
        setFilesLength((prevLength) => prevLength - 1);
        if (!multiple) {
            showToast(t("File moved to team"), { type: "success" });
        }
    }
    const handleCheckboxChange = (fileId, isChecked) => {
        setCheckedItems(prevState => {
            // Step 1: Create a temporary copy of the checkedItems state
            const tempArray = { ...prevState };

            // Step 2: Modify the temporary array
            tempArray[fileId] = isChecked;

            // Get checked items from the updated temporary array
            const checkedIds = Object.keys(tempArray).filter(id => tempArray[id]);

            // Do something if the length of checked items is more than 0
            if (checkedIds.length > 0) {
                // Your logic here
                console.log('One or more items are checked!');
            }

            // Step 3: Return the modified temporary array to update the state
            return tempArray;
        });
    };

    const handleShare = async (oid) => {
        console.log(oid);
        try {
            let baseUrl = window.location.origin;

            let link = baseUrl + "/recording/" + oid;
            await navigator.clipboard.writeText(link);

            showToast(t("File link copied to clipboard"));

            return true;

        } catch (err) {
            console.error('Failed to copy text: ', err);
            return false;
        }

    };
    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
        console.log(e.target.value);
        filterFiles(e.target.value);

    }

    const filterFiles = (query) => {
        const filtered = allFiles.filter(file =>
            file?.TempName?.S.toLowerCase().includes(query.toLowerCase())
        );
        console.log(filtered);
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;
        setFilteredFiles(filtered);
        setFilesToShow(filtered.slice(startIndex, endIndex));
    }
    const onDelete = async (fid, uid) => {
        console.log(fid, uid);

        const response = await fetchDataPost("https://5ex4m1gaw1.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFolder?cid=" + uid + "&tid=" + fid);
        showToast(t("Folder deleted"));
        navigate("/folders")
    };
    const onRename = async (fid, uid, name) => {
        console.log(name, fid, uid);

        const response = await fetchDataPost("https://xc8v5q5vc2.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFolder",
            { "tid": fid, "Tname": name, "cid": uid });
        setFolderName(name);
        showToast(t("Folder renamed"));
    };

    const onMoveMultiple = async (uid, fid) => {
        console.log(fid, uid);
        console.log("kek");
        let count = 0;
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                count++;
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleMove(fileId.replace("#Recorditor#", ""), uid, fid, true);
            }
        }
        setCheckedItems({});
        if (count > 1) {
            showToast(t("Files moved to folder"), { type: "success" });
        } else {
            showToast(t("File moved to folder"), { type: "success" });
        }

    };

    const onDeleteMultiple = async (uid) => {
        console.log("kek");
        let count = 0;
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                count++;
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleDelete(fileId.replace("#Recorditor#", ""), uid, true);
            }
        }
        setCheckedItems({});
        if (count > 1) {
            showToast(t("Files deleted"), { type: "success" });
        } else {
            showToast(t("File deleted"), { type: "success" });
        }
    };

    const selectAll = () => {
        const allChecked = {};

        filesToShow.forEach(file => {
            allChecked[file?.SK?.S] = true;
        });

        setCheckedItems(allChecked);
    };

    const unselectAll = () => {
        const allChecked = {};



        setCheckedItems(allChecked);
    };

    const onShareMultiple = async (uid, tid) => {
        console.log("kek");
        let count = 0;
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                count++;
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleShareTeam(fileId.replace("#Recorditor#", ""), uid, tid, true);
            }
        }
        setCheckedItems({});
        if (count > 1) {
            showToast(t("Files moved to team"), { type: "success" });
        } else {
            showToast(t("File moved to team"), { type: "success" });
        }
    };

    const onCreateFolder = async (tid) => {
        if (userStatus && userStatus === "Trial") {
            showModal("UPGRADE-FOLDER");
        } else {

            showModal('CREATE-FOLDER', { uid: hashedId }, { onCreateFolder: (uid, folderName) => handleCreateFolder(uid, folderName, tid) });
        }
    }
    const handleCreateFolder = async (uid, folderName, tid) => {
        const response = await fetchDataPost("https://lq3fr34z20.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreateFolder",
            { Fname: folderName, cid: uid });
        const data = await response.json();
        fetchFolders();
        showToast(t("Folder created"));
        if (tid) {
            handleMove(tid, uid, data.SK.S);

        } else {
            onMoveMultiple(uid, data.SK.S);
        }
    };
    const onCreateWorkspace = async (tid) => {
        if (userStatus && userStatus === "Trial") {
            showModal("UPGRADE-WORKSPACE");
        } else {
            showModal("CREATE-WORKSPACE", { uid: hashedId }, { onCreateWorkspace: (uid, workspaceName) => handleCreateWorkspace(uid, workspaceName, tid) })
        }
    };
    const handleCreateWorkspace = async (uid, workspaceName, tid) => {
        const response = await fetchDataPost("https://0n4xaw0876.execute-api.eu-central-1.amazonaws.com/default/Create-Team",
            { UserHashedId: uid, TeamName: workspaceName });
        const data = await response.json();
        fetchTeams();
        showToast(t("Workspace created"));
        if (tid) {
            handleShareTeam(tid, uid, data.TeamId, false);
        } else {
            onShareMultiple(uid, data.TeamId);
        }
    };
    return (
        <>
            <>
                <Modal show={showProcessingModal} onHide={() => setShowProcessingModal(false)} centered>
                    <Modal.Body >
                        <button type="button" class="btn-close" onClick={() => setShowProcessingModal(false)} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">{t("The record is in the uploading process")}</h2>
                            <p class="ft-18-title mb-4">{t("This record is still uploading. It will take a few seconds to finalize the uploading.")}</p>
                        </div>
                        <div class="text-center">
                            {processingRecordingLoading ? (
                                <div className="spinner-border text-primary" role="status"> </div>
                            ) : (
                                <></>)}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
            <div className='row'>
                {filesRouteLoading && (
                    <div className='d-flex justify-content-center text-center'>
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>)}
                <div className="col-12" style={{ display: !isFile ? "none" : "" }}>

                    <div className='d-lg-flex d-block justify-content-between align-items-center'>

                        {!isFolder ? (
                            <h2 className="card-title">{t("Files")}</h2>
                        ) : (<>
                            <h2 class="card-title w-100"><Link to="/folders" class="hover-underline">{t("Folders")}</Link> / {folderName}
                                <div class="action-icon-list d-sm-inline-flex d-flex justify-content-end">
                                    <Link onClick={() => showModal('RENAME', { tid: fid, uid: hashedId, name: folderName }, { onRename })}>
                                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                                            <img src={renameSvg} class="img-fluid" alt="img" />
                                        </span>
                                    </Link>
                                    <Link onClick={() => showModal('DELETE-FOLDER', { fid: fid, uid: hashedId }, { onDeleteFolder: onDelete })}>
                                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={("Delete")}>
                                            <img src={deleteSvg} class="img-fluid" alt="img" />
                                        </span>
                                    </Link>
                                </div>
                            </h2>
                        </>)}
                        <div className='w-100 text-end d-lg-block d-none'>
                            {!fid && Object.keys(checkedItems).filter(id => checkedItems[id]).length > 0 && (
                                <>
                                    <button class="btn small-table-btn green-btn-bg me-1 mb-1" onClick={() => showModal('MOVE', { folders: folders, uid: hashedId, onCreateFolder: onCreateFolder }, { onMove: onMoveMultiple })}>
                                        <span class="btn-icon me-2"><img src={moveWhite} class="img-fluid" alt="img" /></span>{t("Move")}
                                    </button>
                                    <button className='btn small-table-btn me-1 mb-1' onClick={() => showModal('SHARE-TEAM', { teams: teams, uid: hashedId }, { onShareTeam: onShareMultiple, onCreateWorkspace: onCreateWorkspace })}>
                                        <span className='btn-icon me-2'><img src={sharePeopleSvg2} className="img-fluid" alt="img" /></span>{t("Add to a workspace")}
                                    </button>
                                    <button class="btn small-table-btn red-btn-bg me-1 mb-1" onClick={() => showModal('DELETE', { uid: hashedId }, { onDelete: onDeleteMultiple })} >
                                        <span class="btn-icon me-2"><img src={deleteWhite} class="img-fluid" alt="img" /></span>{t("Delete")}
                                    </button>
                                    <button class="btn small-table-btn me-1 mb-1" onClick={() => selectAll()} style={{ display: Object.keys(checkedItems).filter(id => checkedItems[id]).length === filesToShow.length ? "none" : "" }}>
                                        <span class="btn-icon me-2"><img src={checkAllWhite} class="img-fluid" alt="img" /></span>{t("Select All")}
                                    </button>
                                    <button class="btn small-table-btn me-1 mb-1 red-btn-bg" onClick={() => unselectAll()} style={{ display: Object.keys(checkedItems).filter(id => checkedItems[id]).length === filesToShow.length ? "" : "none" }}>
                                        <span class="btn-icon me-2">x</span>{t("Unselect All")}
                                    </button>
                                </>
                            )}
                            <div class="mb-4 table-serach input-icon-div d-lg-inline-block d-block ms-auto mt-lg-0 mt-3">
                                <input type="text" class="form-control round-input" name="" placeholder={t("Search...")} value={searchQuery} onChange={handleInputChange} />
                                <span class="input-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-12">
                    <div className={`${isFile ? "card card-padding" : ""}`}>
                        <div className='table-responsive'>
                            {isLoading ? (
                                <table className='table'>
                                    <tbody>

                                        {Array.from({ length: isFile ? 10 : 5 }).map((_, index) => (
                                            <tr key={index} style={{ height: "51.07px" }}>
                                                <Skeleton height={30} style={{ borderRadius: '10px', marginTop: "21.07px" }} />

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                filesToShow.length === 0 ? (
                                    <div className='d-flex justify-content-center text-center'>
                                        <div className='card no-file-card text-center'>
                                            <div className='card-body'>
                                                <div className='text-center'>
                                                    <p>{t("No file available")}</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <table className='table'>
                                        <tbody>
                                            {filesToShow.map((file, index) => (
                                                <FileItem
                                                    key={file?.SK?.S}
                                                    file={file}
                                                    index={index + ((currentPage - 1) * filesPerPage)}
                                                    onDelete={handleDelete}
                                                    onRename={handleRename}
                                                    onMove={handleMove}
                                                    onShare={handleShare}
                                                    onShareTeam={handleShareTeam}
                                                    folders={folders}
                                                    teams={teams}
                                                    isChecked={checkedItems[file?.SK?.S] || false}
                                                    onCheckboxChange={handleCheckboxChange}
                                                    isFile={isFile}
                                                    onProcessing={fetchFiles}
                                                    isFolder={isFolder}
                                                    onCreateFolder={() => onCreateFolder(file?.SK?.S.replace('#Recorditor#', ""))}
                                                    onCreateWorkspace={() => onCreateWorkspace(file?.SK?.S.replace('#Recorditor#', ""))}
                                                    onProcessingFileClicked={onProcessingFileClicked}

                                                />
                                            ))}

                                            {Array.from({ length: filesPerPage - filesToShow.length }).map((_, index) => (
                                                <tr key={index} style={{ height: "51.07px" }}>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                )
                            )}

                        </div>

                        <nav aria-label="..." className={`${filesToShow.length === 0 ? 'd-none' : ''}`} >
                            <ul className='pagination pagination-sm justify-content-end'>
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" tabIndex="-1" aria-disabled="true" onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
                                        <span className="fa fa-angle-left"></span>
                                    </Link>
                                </li>

                                {/* Show previous page if it's not the first page */}
                                {currentPage > 1 && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>
                                            {currentPage - 1}
                                        </Link>
                                    </li>
                                )}

                                {/* Current Page */}
                                <li className="page-item active">
                                    <Link className="page-link" to="#" onClick={(e) => e.preventDefault()}>
                                        {currentPage}
                                    </Link>
                                </li>

                                {/* Show next page if it's not the last page */}
                                {currentPage < Math.ceil(filesLength / filesPerPage) && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>
                                            {currentPage + 1}
                                        </Link>
                                    </li>
                                )}

                                <li className={`page-item ${currentPage === Math.ceil(filesLength / filesPerPage) ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(filesLength / filesPerPage)))}>
                                        <span className="fa fa-angle-right"></span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </>


    );
}

export default Files;
