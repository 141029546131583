import React, { useState, useEffect, Suspense, useRef } from 'react';
import logo from './logo.svg';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { BrowserRouter, Routes, Route, Link, useNavigate, useLocation, Navigate, redirect } from 'react-router-dom';
import Files from './pages/Files/Files';
import Payment from './pages/Payment';
import Folders from './pages/Folders/Folders';
import Workspaces from './pages/Workspaces/Workspaces';
import Workspace from './pages/Workspace';
import Rewards from './pages/Rewards';
import Editor from './pages/Editor/Editor';
import Folder from './pages/Folder';
import Dashboard from './pages/Dashboard';
import LoginComponent from './pages/SignIn';
import SignUpComponent from './pages/SignUp';
import Account from './pages/Account';
import Recorder from './pages/Recorder/Recorder';
import Enterprise from './pages/Enterprise';
import MeetingAssistant from './pages/MeetingAssistant';
import Sharing from './pages/Sharing/Recording';
import ReceivedPayment from './pages/ReceivedPayment';
import Authentication from './pages/Authentication';
import ConnectionStatus from './components/common/ConnectionStatus';
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.css'
import '../src/assets/css/custome.css';
import '../src/assets/css/responsive.css';
import '../src/assets/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Gleap from "gleap";


import { AuthProvider, useAuth } from './Auth/AuthContext';
import { ModalProvider } from './components/common/Modal/ModalContext';
import BootstrapModal from './components/common/Modal/BootstrapModal';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Billing from './pages/Billing';
import ErrorBoundary from './components/common/errorBoundary';
import { DirectionProvider, useDirection } from './components/common/DirectionContext';
import { showToast } from './components/common/toast';
import RecordingView from './pages/Recording/Recording';
import { RecorderProvider } from './pages/Recorder/RecorderContext';
import { UploadProvider } from './pages/Uploader/UploaderContext';
import { LanguageProvider, loadLanguageTranslations } from './components/common/LanguageProvider';
import amplitude from 'amplitude-js';
import DiscountView from './pages/DiscountView';

// ... other imports ...


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function AppContent() {
  const [isOpen, setIsOpen] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const location = useLocation();


  const handleOpenSidebar = () => setIsOpen(true);
  const handleCloseSidebar = () => setIsOpen(false);
  const mainRef = useRef(null);
  const { currentUser, logout, userData } = useAuth();
  const { direction } = useDirection();
  /*const [shouldCheck, setShouldCheck] = useState(() => {
    // Get the shouldCheck state from sessionStorage
    const storedValue = sessionStorage.getItem('shouldCheck');
    return storedValue === null ? true : JSON.parse(storedValue);
  });
  


  const handleSlowConnection = () => {
    if (shouldCheck) {
      showToast("Your internet connection is slow. This might affect your experience.", "warning")

      // Set the shouldCheck state to false in sessionStorage to avoid rechecking after reload
      sessionStorage.setItem('shouldCheck', JSON.stringify(false));
      window.location.reload(); // refresh the page
    }else{
      showToast("Your internet connection is slow. This might affect your experience.", "warning")
    }
  };*/

  useEffect(() => {
    document.documentElement.setAttribute('dir', direction);
  }, [direction]);
  const navigate = useNavigate();
  const checkRemember = async () => {
    const rememberSession = sessionStorage.getItem('remember');
    const rememberLocal = localStorage.getItem('remember');
    if (!rememberSession && rememberLocal === 'false') {
      await logout();
    }
  };
  const queryParams = new URLSearchParams(location.search);
  // To retrieve a specific query parameter:
  const redirect = queryParams.get('redirect');
  const sharing = location.pathname.startsWith('/sharing');
  amplitude.getInstance().init("a4563a2706e1e084439f19815d3b36f8", null,
  {
    includeGclid: true,
    includeReferrer: true,
    includeUtm: true,
  }
);
  useEffect(() => {
    checkRemember();

    if (!redirect && !currentUser && !sharing) {
      if (location.pathname.startsWith("/recording")) {
        localStorage.setItem('redirectRecording', location.pathname);
      }
      if (!queryParams.get('t')) {
        navigate('/signIn');

      }
      console.log("currentUser is not logged in");

    } else {
      if(!queryParams.get('t')){
      console.log("currentUser is logged in");
      if (localStorage.getItem('redirectRecording')) {
        const redirectRecording = localStorage.getItem('redirectRecording');
        localStorage.removeItem('redirectRecording');
        navigate(redirectRecording);
      }
    }
        
    }

  }, [currentUser, navigate]);


  if (!currentUser && !redirect) {
    return null;
  }
  Gleap.initialize("4zYxUzfrrldHMuIRx0yIafmuI8zC4WI5");
  Gleap.setLanguage(localStorage.getItem('dashboard-language') || "en")

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };
  const hideHeader = (!isMobileDevice()  &&  (location.pathname.startsWith("/recording") || location.pathname === "/payment")) || location.pathname.startsWith("/sharing");



  return (
    <>

      <RecorderProvider>
      <UploadProvider>
        <Recorder />
        <Sidebar isOpen={isOpen} handleCloseSidebar={handleCloseSidebar} isBlocked={isBlocked} />
        <div className={`main-div ${isOpen ? "disable-layer" : ""}`} >
          {hideHeader ? "" : <Header handleOpenSidebar={handleOpenSidebar} />}



          <div className={hideHeader ? 'content-area pb-0 ' : 'content-area notranslate'}>
            <div className={hideHeader ? 'min-100vh-relative-div' : 'container-fluid p-0 notranslate'}>
              <Routes>

                <Route path="/files" element={<Files isFile={true} />} />
                <Route path="/payment" element={ userData?.Appsumo === 'enabled' ? <DiscountView /> : <Payment handleOpenSidebar={handleOpenSidebar} />} />
                <Route path="/folders" element={<Folders />} />
                <Route path="/workspaces" element={<Workspaces />} />
                <Route path="/discount" element={<DiscountView />} />
                <Route path="/rewards" element={<Rewards />} />
                <Route path="/folder/:fid/:uid" element={<Folder />} />
                <Route path="/workspace/:tid" element={<Workspace />} />
                <Route path="/editor/:tid?/:uid?" element={<Editor handleOpenSidebar={handleOpenSidebar} />} />
                <Route path="/dashboard" element={<Dashboard isBlocked={isBlocked} setIsBlocked={setIsBlocked} />} />
                <Route path="/account" element={<Account />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/enterprise" element={<Enterprise />} />
                <Route path="/meeting-assistant" element={<MeetingAssistant />} />
                <Route path="/payment-received" element={<ReceivedPayment />} />
               <Route path="/recording/:tid/:uid" element={<RecordingView handleOpenSidebar={handleOpenSidebar} />} />
               <Route path="/recording/:oid" element={<RecordingView handleOpenSidebar={handleOpenSidebar} />} />
                <Route path="*" element={<Navigate to="/dashboard" replace />} />



              </Routes>
            </div>
          </div>

        </div>

      </UploadProvider>
      </RecorderProvider>

    </>
  );
}

function App() {
  loadLanguageTranslations(localStorage.getItem("dashboard-language") || 'en');

  return (
    <ErrorBoundary>
      <DirectionProvider>
      <LanguageProvider>
        <ModalProvider>

          <BrowserRouter>

            <AuthProvider>

              <div className="App scale75">
                <BootstrapModal />

                <ToastContainer />

                <Routes>
                  <Route path="/sharing/:oid?" element={<Sharing />} />
                  <Route path='/authentication' element={<Authentication />} />
                  <Route path="/signIn" element={<LoginComponent />} />
                  <Route path="/register" element={<SignUpComponent />} />
                  <Route path="*" element={<AppContent />} />
                </Routes>
              </div>

            </AuthProvider>

          </BrowserRouter>
        </ModalProvider>
        </LanguageProvider>
      </DirectionProvider>

    </ErrorBoundary>

  );
}

export default App;


