import React, {useEffect, useState} from 'react';
import { useApiData } from '../hooks/useApiData';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Files from './Files/Files';
import Folders from './Folders/Folders';

import renameSvg from '../assets/img/icon/rename.svg'
import viewSvg from '../assets/img/icon/view.svg'
import deleteSvg from '../assets/img/icon/delete.svg'
import {useModal} from '../components/common/Modal/ModalContext'
import { fetchDataPost } from '../hooks/apiUtils';
import { showToast } from '../components/common/toast';
import { useAuth } from '../Auth/AuthContext';
import { useTranslation } from '../components/common/LanguageProvider';
function Workspace(props) {
    const { t } = useTranslation();
    const { tid, uid } = useParams();
    const {hashedId} = useAuth();
    const [teamName, setTeamName] = useState("");
    const [members, setMembers] = useState([]);
    const [ownerId, setOwnerId] = useState("");     
    const [ownerMail, setOwnerMail] = useState("");
    const navigate = useNavigate();
    const { showModal } = useModal();
    const getTeamDetails = async () => {
        fetch("https://w31p7ivup6.execute-api.eu-central-1.amazonaws.com/default/Get-Team-Member-Mails-v2-website?TeamId=" + tid).then(res => res.json()).then((result) => {
            setTeamName(result?.TeamName);
            setMembers(result?.Members);
            setOwnerId(result?.OwnerHashedId)
            setOwnerMail(result?.OwnerEmail)
            console.log(result?.Members)
            console.log("result");
            console.log(result);
        }).catch((error) => {});
    };
    useEffect(() => {
        getTeamDetails();
    }, [tid, uid]);

    const handleRename = async(name) => {
        showToast("Workspace will be renamed soon", {type: "info"});
        const response = await fetchDataPost("https://5j5dwdqmo2.execute-api.eu-central-1.amazonaws.com/default/Rename-Team", {TeamId: tid, NewName: name});
        getTeamDetails();
        showToast("Workspace renamed successfully", {type: "success"});
    }
    const openRemoveDialog = async(email) => {
        showModal("REMOVE-ENTERPRISE-MEMBER", {email}, {onRemoveMember: handleRemoveMember})
    }
    const handleRemoveMember = async(email) => {
        showToast("Member will be removed soon", {type: "info"});
        fetch(
            "https://pwiuw9f0y5.execute-api.eu-central-1.amazonaws.com/Delete-Member-From-Team?TeamId=" + 
            tid + "&DeletedMemberEmail=" + email)
            .then(function (){
              getTeamDetails();
                showToast("Member removed successfully", {type: "success"});
          });
    }
    const handleDeleteTeam = async() => {
        showToast("Team will be deleted soon", {type: "info"});
        fetch("https://v5ls3q1wp7.execute-api.eu-central-1.amazonaws.com/Delete-Team?TeamId=" + tid).then(function(){
            showToast("Workspace deleted successfully", {type: "success"})
            navigate("/workspaces");
        });
    }
    const handleInviteTeam = async(email) => {
        showToast("Invitation will be sent soon", {type: "info"});
        const response = await fetchDataPost("https://uvx4z70bsl.execute-api.eu-central-1.amazonaws.com/Invite-User-To-Team?", {TeamId: tid, InvitedEmail: email, TeamName: teamName})
        showToast("Invitation sent successfully", {type: "success"});
    };

    const handleLeaveTeam = async() => {
        showToast("You will leave the team soon", {type: "info"});
        fetch(
            "https://pwiuw9f0y5.execute-api.eu-central-1.amazonaws.com/Delete-Member-From-Team?TeamId=" +
             tid + "&DeletedMemberHashedId=" + hashedId)
            .then(function (){
                showToast("You left the workspace successfully", {type: "success"});
                setTimeout(() => {
                    navigate("/workspaces");

                }, 2000);
                
          });
    };
    return (
        <>			
        <div class="row">
            <div class="col-xxl-12 col-12">
            <div class="d-lg-flex d-block justify-content-between align-items-center">
                <h2 class="card-title w-100"><Link to="/workspaces" class="hover-underline">{t("Workspaces")}</Link> / {teamName}
                    <div class="action-icon-list d-sm-inline-flex d-flex justify-content-end">
                          <Link  onClick={()=> showModal("RENAME", {name: teamName}, {onRenameTeam: handleRename})} style={{display: ownerId !== hashedId ? "none": ""}}>
                              <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                                  <img src={renameSvg} class="img-fluid" alt="img"/>
                              </span>
                          </Link>
                          <Link  onClick={()=> showModal("SHOW-TEAM-MEMBERS", {members, ownerMail, ownerId}, {onRemoveMember: openRemoveDialog})}>
                              <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("View")}>
                                  <img src={viewSvg} class="img-fluid" alt="img"/>
                              </span>
                          </Link>
                          <Link  onClick={()=> showModal("DELETE-TEAM", {onDeleteTeam: handleDeleteTeam})} style={{display: ownerId !== hashedId ? "none": ""}}>
                              <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Delete")}>
                                  <img src={deleteSvg} class="img-fluid" alt="img"/>
                              </span>
                          </Link>
                          <Link onClick={()=> showModal("LEAVE-TEAM", {onLeaveTeam: handleLeaveTeam})}style={{display: ownerId === hashedId ? "none": ""}}>
                            <span className='icons-hover theme-color'data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Leave")}>
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="#ff3938" fill='none' stroke-width="2">
                                        <path d="M8,3 L22,3 L22,21 L8,21 L8,3 Z" />
                                        <path d="M17,10 L12,10" />
                                        <path d="M14,7 L14,13" />
                                        <path d="M8,12 L4,12" />
                                        <path d="M4,9 L1,12 L4,15" />
                                    </g>
                                </svg>

                            </span>
                          </Link>
                          <Link onClick={()=> showModal("ADD-ENTERPRISE-MEMBER", {onInviteTeam: handleInviteTeam})}>
                              <span class="icons-hover theme-color" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Invite a friend")}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person-add" viewBox="0 0 16 16">
                                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                                    <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>
                                </svg>
                              </span>
                          </Link>
                          
                      </div>
                </h2>
                <div class="mb-4 table-serach input-icon-div d-lg-block d-none ms-auto">
                    <input type="text" class="form-control round-input" name="" placeholder={t("Search...")}/>
                    <span class="input-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                    </span>
                </div>
            </div>
            </div>
        </div>
   				<div className='row'>
                   <div class="col-lg-4 col-12 card-margin">
                    <div className='card card-padding card-100'>
                        
                        <Folders tid={tid} uid={uid} isFile={true} isFolder={false} />

                    </div>

                </div>
                <div class="col-lg-8 col-12 card-margin">
                    <div className='card card-padding  card-100'>
                    <Files tid={tid} uid={uid} isFile={false} />
                    </div>
                </div>
                </div>

    
        </>
        );
}

export default Workspace;
