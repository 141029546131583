import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../Auth/AuthContext';
import upgrade from '../assets/img/icon/upgrade.svg';
import folders from '../assets/img/icon/folders.svg';
import { useNavigate, useLocation } from "react-router-dom";
import Files from './Files/Files';
import { useModal } from '../components/common/Modal/ModalContext';
import RecorderComponent from './Recorder/RecorderComponent';
import { useTranslation } from '../components/common/LanguageProvider';
import logoTranskriptor from '../assets/img/logo-transkriptor-nobuffer.png';

function Dashboard({ isBlocked, setIsBlocked }) {
  const { planInfo, userData, hashedId } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const location = useLocation();
  const isChrome = window.navigator.userAgent.includes("Chrome")


  useEffect(() => {
    if (!localStorage.getItem("transcript-language"))
      findLanguage();
    if (!localStorage.getItem("isLanguageSet")) {
      localStorage.setItem("isLanguageSet", true);
      showModal("LANGUAGE-MODAL");

    }
  }, []);

  const handleNavigate = (e, brand) => {
    e.preventDefault();

    // Construct new URL
    const newURL = `https://app.${brand}.com/signIn?uid=${hashedId}`;

    // Redirect to the new URL
    if (isChrome) {
      window.open(newURL, "_blank");
    } else {
      window.location.href = newURL;
    }
  };


  const findLanguage = async () => {
    try {
      const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
      const [iso639_1, countryCode] = browserLanguage.split("-");

      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || 'US');
      localStorage.setItem("transcript-language", browserLanguage);
      window.location.reload();

    } catch (error) {
      console.error('Error detecting browser language:', error);
    }
  };

  return (
    <>
      <div className='row justify-content-center'>
        {!isBlocked &&
          <RecorderComponent isTrial={userData?.SStatus === "Trial"} />}
        <div class="col-lg-4 card-margin" >
          <a
            href={`https://app.transkriptor.com/signIn?uid=${hashedId}`}
          >
            <div class="card card-padding card-100  text-center card-hover" onClick={(e) => handleNavigate(e, "transkriptor")}>
              <div className='img-icon-list'>
                <img src={logoTranskriptor} className='img-fluid recorder-img' alt='img' style={{ height: '50px' }}></img>
              </div>
              <p class="ft-18-title">{t("Convert Audio To Text")}</p>

              <p>{t("Automatically transcribe your meetings, interviews, lectures, and other conversations.")}</p>
            </div>
          </a>
        </div>
        <div className='col-lg-8 card-margin'>
          <div className='card card-padding card-100 '>
            <Files isFile={false} />
          </div>
        </div>
        <div class="col-lg-4 card-margin d-lg-block d-none">
          <div class="row h-100">
            <div class="col-12 mb-3">
              <a href="payment">
                <div class="card card-padding card-100 flex-center card-hover" onClick={(e) => {
                  e.preventDefault();
                  navigate("/payment")
                }}>
                  <div class="w-100 d-sm-flex d-block align-items-center justify-content-between">
                    <img src={upgrade} class="img-fluid order-2 mb-sm-0 mb-3" alt="img" />
                    <div class="me-3 order-1">
                      <p class="ft-18-title mb-2">{t("Upgrade")}</p>
                      <p class="mb-0">{t("Upgrade your account to record more videos and get the transcription of videos")}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-12 ">
            <a href="folders">
              <div class="card card-padding card-100 flex-center card-hover" onClick={(e) => {
                e.preventDefault();
                navigate("/folders")}}>
                <div class="w-100 d-sm-flex d-block align-items-center justify-content-between">
                  <img src={folders} class="img-fluid order-2 mb-sm-0 mb-3" alt="img" />
                  <div class="me-3 order-1">
                    <p class="ft-18-title mb-2">{t("Folders")}</p>
                    <p class="mb-0">{t("Create folders and arrange your files as your needs.")}</p>
                  </div>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Dashboard;



