import { useParams, Link, useLocation} from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";

import filesMarkSvg from "../../assets/img/files/file-mark.svg";
import renameSvg from "../../assets/img/icon/rename.svg";
import { fetchData, fetchDataPost } from "../../hooks/apiUtils";

import Transcription from "./Transcription";
import Player from "./Player";
import Failed from "./Failed";
import Processing from "./Processing";

import tabTranskriptorBlue from "../../assets/img/files/tab-transkriptor-blue.png";
import tabTranskriptorWhite from "../../assets/img/files/tab-transkriptor-white.png";
import tabChatBotBlue from "../../assets/img/files/tab-chatbot-blue.svg";
import tabChatBotWhite from "../../assets/img/files/tab-chatbot-white.svg";
import undoSvg from "../../assets/img/files/undo.svg";
import redoSvg from "../../assets/img/files/redo.svg";
import shareSvg from "../../assets/img/files/share.svg";
import { useModal } from "../../components/common/Modal/ModalContext";
import { async } from "@firebase/util";
import { showToast } from "../../components/common/toast";
import Download from "./Download";
import { useAuth } from "../../Auth/AuthContext";
import AiChat from "./AiChat";
import logoIcon from "../../assets/img/icon/logo-icon.png";
import { useTranslation } from "../../components/common/LanguageProvider";
function Editor({handleOpenSidebar}) {
  let { tid, uid } = useParams();
  const { t } = useTranslation();
  const { userData, hashedId } = useAuth();
  const [content, setContent] = useState([]);
  const [filename, setFilename] = useState(null);
  const [audio, setAudio] = useState(null);
  const [duration, setDuration] = useState(null);
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [focusedTime, setFocusedTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [failCode, setFailCode] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [speakerList, setSpeakerList] = useState([]);
  const [userStatus, setUserStatus] = useState(null);
  const [userTrial, setUserTrial] = useState(null);
  const [ref, setRef] = useState(null);
  const [fontSize, setFontSize] = useState(localStorage.getItem("fontSize") || 15);
  const [addUnloadListener, setAddUnloadListener] = useState(false);
  const contentRefs = useRef([]);
  const [editing, setEditing] = useState(false);
  const undoRef = useRef(null);
  const redoRef = useRef(null);
  const fontSizeRef = useRef(null);
  const saveRef = useRef(null);
  const { showModal } = useModal();
  const [focus, setFocus] = useState(0);
  const [slideFocus, setSlideFocus] = useState(0);
  const [playBlock, setPlayBlock] = useState(false);
  var oid = null;
  var isSharing = false;
  var focusTime = 0;
  // First let's set the colors of our sliders
  const settings = {
    fill: "var(--theme-color)",
    background: "#E0E8F1",
  };
  const location = useLocation();


  // If tid and uid are not available, try to get them from the query string
 if ((!tid || !uid) || !oid) {
    const params = new URLSearchParams(location.search);
    tid = tid || params.get('tid');
    uid = uid || params.get('fid');
    oid = oid || params.get("oid");
  }
  // This function applies the fill to our sliders by using a linear gradient background
  function applyFill(slider) {
    // Let's turn our value into a percentage to figure out how far it is in between the min and max of our input
    const percentage =
      (100 * (slider.value - slider.min)) / (slider.max - slider.min);
    // now we'll create a linear gradient that separates at the above point
    // Our background color will change here
    const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${
      settings.background
    } ${percentage + 0.1}%)`;
    slider.style.background = bg;
  }
  // Now you can use tid and uid in this component
  const getTranscript = async () => {
	setIsLoading(true);
  var temp_url = "https://9oqfyb5h9i.execute-api.eu-central-1.amazonaws.com/default/AA-API-Gettranscription-content?cid=" +uid +"&transcription_id=" +tid;
  if(oid){
    temp_url = "https://sqxnupivk2.execute-api.eu-central-1.amazonaws.com/default/AA-API-Gettranscription-content-v2?OrderId="+ oid;
  }
    const response = await fetchData(temp_url);
    console.log("gell");
	console.log(response);
	setIsLoading(false);
	setStatus(response["status"]);
	if(response["status"] == "Failed"){
		setFailCode(response["failCode"]);
	}else if(response["status"] == "Processing"){

	}else if(response["status"] == "Completed"){
    console.log(response)
    console.log("lelele")
    // Iterate over the response["content"] array
    let temp_content = []
    response["content"].forEach((item) => {
      // Check if contentItem has a text property that is defined, not null and not an empty string
      if(item.text && item.text.trim() !== "") {
       
        temp_content.push(item);
        // Or if you are supposed to call setContent for each non-empty text
        // setContent(contentItem.text);
      }
    });
		setContent(temp_content);

		setAudio(response["sound"]);
		setDate(convertMsToFormattedDate(response["date"]));
		setFilename(response["filename"]);
    if(oid){
      tid = response["tid"];

    }else{
      setOrderID(response["orderid"]);

    }
    setUserTrial(response["userTrial"] === "yes" ? true : false);
    setAddUnloadListener(true);
    applyFill(fontSizeRef.current);
    

	}else{
    showToast(t("Unknown error, please contact support"), {type: "error"});
  }
  };




  useEffect(() => {
    getTranscript();
  }, []);
useEffect(()=>{
  if(oid){
    isSharing = true;
    setOrderID(oid);
  }

}, [oid])
  useEffect(() => {
    if(userData)
      setUserStatus(userData.SStatus);
  }, [userData]);

  useEffect(() => {
    const sliders = document.querySelectorAll(".range-slider");
    Array.prototype.forEach.call(sliders, (slider) => {
      slider.querySelector("input").addEventListener("input", (event) => {
        slider.querySelector("span").innerHTML = event.target.value;
        applyFill(event.target);
      });
      applyFill(slider.querySelector("input"));
    });
  }, []);

  const goToBlock = (voiceStart) => {
    console.log(voiceStart);
    setFocusedTime(voiceStart);
    if(document.getElementById("audio-src")){
      let audio = document.getElementById("audio-src");
      audio.play()
    }
  };
  useEffect(() => { 
    setFocusedTime(focusedTime)
  }, [focusedTime]);
  const handleTranscriptContent = (event, index) => {
    console.log("kekxce")
      let sanitizedContent = sanitizeContent(event.target.innerText);
      event.target.innerText = sanitizedContent;
	    var text = event.target.innerText;
	    // Create a new array with the updated content
      const updatedContent = [...content];
      updatedContent[index].text = text;
      
      setContent(updatedContent); 
  };
  const sanitizeContent = (content) => {
    return content.replace(/&nbsp;/g, ' ').trim();
};

  const onRename = async (tid, uid, name) => {
    setFilename(name);
    const response = await fetchDataPost(
      "https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?",
      { tid: "#Transkription#" + tid, Tname: name, cid: "User#" + uid }
    );
    showToast(t("File renamed"), { type: "success" });
  };

  	function convertMsToFormattedDate(ms) {
      ms = parseInt(ms);
      const date = new Date(ms);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns a 0-based value
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
	}

	const saveTranscription = async () => {
    
    let temp_url = "https://nagikjupxb.execute-api.eu-central-1.amazonaws.com/default/AA-API-SaveTranscriptionContent?fid=" + uid + "&tid=" + tid;
    if(oid){
      temp_url = "https://ikhko6x5wi.execute-api.eu-central-1.amazonaws.com/default/AA-API-SaveTranscriptionContent-v2?OrderId="+oid;
    }
    const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
    if(response.SStatus === "Trial"){
        showModal("UPGRADE-SAVE");
    }else{
      const response = await fetchDataPost(temp_url, content);
      showToast(t("Your work has been saved"), { type: "success" });
      getTranscript();
    }
		
	};
  const handleKeyDown = (e, contentRef, index) => {
    setEditing(true);
    var kc = e.which || e.keyCode;

    const position = getCaretPosition(e.target);

    // Handle the Tab key to go to the next line
   // Handle the Tab key to go to the next ref
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent default behavior (like changing focus to the next focusable element)
      
      // Check if there's a next ref in the array and if it exists
      if (contentRefs.current[index + 1]) {
        contentRefs.current[index + 1].focus();
      }
      return;
    }else if(e.altKey && e.key === "ArrowUp"){
      e.preventDefault();
      if(contentRefs.current[index - 1]){
        contentRefs.current[index - 1].focus();
      }
      return;
    }
    if (e.key === "ArrowRight" && position === e.target.textContent.length) {

      let nextElem = contentRefs.current[index + 1]
      if (!nextElem) return;
      
        nextElem.focus();
    } else if (e.key === "ArrowLeft" && position === 0) {
      let prevElem = contentRefs.current[index - 1]
      if (!prevElem) return;
      
        prevElem.focus();
        
    } else if (e.key === "Enter") {
      e.preventDefault();
    
      // Get current text and split it at caret position
      const currentText = contentRefs.current[index].innerText.trim();
      const beforeCaret = currentText.slice(0, position);
      const afterCaret = currentText.slice(position);
      console.log(content)
      console.log("bababa")
      // If caret is at the end of the string, there is nothing to split.
      if (position === currentText.length) return;
    
      const currentItem = content[index];
      const { StartTime, EndTime, VoiceStart, VoiceEnd } = currentItem;
      
      // Calculate ratio to split the time and voice properties
      const ratio = beforeCaret.length / currentText.length;
    
      // Create the first and second items with updated properties
      const firstItem = {
        ...currentItem,
        text: beforeCaret,
        VoiceEnd: VoiceStart + Math.round((VoiceEnd - VoiceStart) * ratio),
        EndTime: StartTime + Math.round((EndTime - StartTime) * ratio),
      };
    
      const secondItem = {
        ...currentItem,
        text: afterCaret,
        VoiceStart: firstItem.VoiceEnd,
        StartTime: firstItem.EndTime,
      };
    
      // Update content array and set new content
      const updatedContent = [...content.slice(0, index), firstItem, secondItem, ...content.slice(index + 1)];
      setContent(updatedContent);
      
      // Focus on the newly created item
      setTimeout(() => {
        if (contentRefs.current[index + 1]) contentRefs.current[index + 1].focus();
      }, 0);
    }
    
    if (e.key === "Backspace" && position === 0) {
      e.preventDefault();
  
      // Check if there's a previous index
      if (content[index - 1]) {
          // Merge the content of the current line with the previous line
          const prevText = content[index - 1].text;
          const currentText = contentRefs.current[index].innerText.trim();
          const updatedItem = {
              ...content[index - 1],
              text: prevText + " " + currentText,
              VoiceEnd: content[index].VoiceEnd,
              EndTime: content[index].EndTime
          }
          
          // Update the content array
          let updatedContent = [...content];
          updatedContent[index - 1] = updatedItem;
          updatedContent.splice(index, 1);
  
          // Update the state
          setContent(updatedContent);
  
          setTimeout(() => {
              const elem = contentRefs.current[index - 1];
              if (elem) {
                  elem.focus();
                  const textLength = prevText.length + 1; // +1 for the space between merged texts
                  
                  const range = document.createRange();
                  range.setStart(elem.childNodes[0] || elem, textLength);
                  range.collapse(true);
  
                  const selection = window.getSelection();
                  selection.removeAllRanges();
                  selection.addRange(range);
              }
          }, 0);
      }
  }
  
  
  
  if (e.key === "Delete" && position === e.target.textContent.length) {
    e.preventDefault();
    
    // Check if there's a next index
    if (content[index + 1]) {
        const nextText = content[index + 1].text;
        const currentText = contentRefs.current[index].innerText.trim();
        
        const updatedItem = {
            ...content[index],
            text: currentText + " " + nextText,
            VoiceEnd: content[index + 1].VoiceEnd,
            EndTime: content[index + 1].EndTime
        }
        
        let updatedContent = [...content];
        updatedContent[index] = updatedItem;
        updatedContent.splice(index + 1, 1);
        
        // Update the state
        setContent(updatedContent);

        setTimeout(() => {
            const elem = contentRefs.current[index];
            if (elem) {
                elem.focus();
                
                const range = document.createRange();
                range.setStart(elem.childNodes[0] || elem, position);
                range.collapse(true);

                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }, 0);
    }
}

 
    if ((e.ctrlKey || e.metaKey) ) {
      switch (e.key.toLowerCase()) {
        
        case 'a':
          e.preventDefault();
          if(userStatus && userStatus === "Trial"){
            showModal("UPGRADE-COPY");
            return;
          }
          if (contentRefs.current[index]) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(contentRefs.current[index]);
            selection.removeAllRanges();
            selection.addRange(range);
          }
          break;
        case 'c':
            e.preventDefault();
            if(userStatus && userStatus === "Trial"){
              showModal("UPGRADE-COPY");
              return;
            }
            navigator.clipboard.writeText(window.getSelection().toString())
              .then(() => {
                console.log('Text successfully copied to clipboard!');
              })
              .catch(err => {
                console.error('Could not copy text: ', err);
              });
            break;
        case 'v':
              e.preventDefault();
              if (userStatus && userStatus === "Trial") {
                showModal("UPGRADE-COPY");
                return;
              }
              navigator.clipboard.readText()
                .then(clipText => {
                  const currentItem = contentRefs.current[index];
                  const currentText = currentItem.innerText;
                  const caretPos = getCaretCharacterOffsetWithin(currentItem);
                  const newText = currentText.slice(0, caretPos) + clipText + currentText.slice(caretPos);
                  currentItem.innerText = newText;
                })
                .catch(err => {
                  console.error('Could not read clipboard: ', err);
                });
              break;
        case "1":
        case "2":
        case "3":
        case "4":
          e.preventDefault();

            const selectedSpeakerIndex = parseInt(e.key, 10) - 1;
            // Modulo operation to cycle through available speakers if the selected speaker index is out of range
            const speakerIndex = selectedSpeakerIndex % speakerList.length;
            handleChangeSpeaker(speakerList[speakerIndex], index);
          
          break;
        case "5":
          e.preventDefault();

          let currentSpeakerIndex = speakerList.findIndex(s => s === content[index].Speaker);

          const nextSpeakerIndex = (currentSpeakerIndex + 1) % speakerList.length;
          handleChangeSpeaker(speakerList[nextSpeakerIndex], index);
          break;
        }
    
    }
    
  
    
    
            
    
  };
  function getCaretCharacterOffsetWithin(element) {
    let caretOffset = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    const sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  }
  
  const mergeSameSpeakers = () => {
    const transcriptListItems = Array.from(document.querySelectorAll('.transkript-list'));
    let updatedContent = [];
    let lastSpeaker = null;
    let mergedItem = null;
    console.log(transcriptListItems.length)
    console.log(content.length)
    console.log("alalala")
    transcriptListItems.forEach((item, index) => {
      const speaker = item.querySelector(".transcript-dropdown").querySelector("a").textContent;
      const transcriptContent = item.querySelector('.transcript-content').textContent;
      const voiceStart = item.querySelector('.transcript-time').textContent;
      console.log(voiceStart);
      console.log(speaker)
      console.log(transcriptContent)

      
      if(lastSpeaker !== speaker) {
        if(mergedItem) {
          updatedContent.push(mergedItem);
        }
        mergedItem = {...content[item], Speaker: speaker, text: transcriptContent, VoiceStart: timeToMs(voiceStart) };
        lastSpeaker = speaker;
      } else {
        mergedItem.text += ' ' + transcriptContent;
      }
    });
    
    if(mergedItem) updatedContent.push(mergedItem);
    
    setContent(updatedContent);
  };
  function timeToMs(timeString) {
    const parts = timeString.split(':');
    if(parts.length !== 3) throw new Error('Invalid time string format');
    
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);
    
    if(isNaN(hours) || isNaN(minutes) || isNaN(seconds)) throw new Error('Invalid time string');
    
    return ((hours * 60 * 60) + (minutes * 60) + seconds) * 1000;
}

  useEffect(() => {
    const handleCopy = (e) => {
      if (userStatus && userStatus === "Trial") {
        e.preventDefault();
        showModal("UPGRADE-DOWNLOAD");
      }
    };
  
    document.addEventListener('copy', handleCopy);
    
    return () => {
      document.removeEventListener('copy', handleCopy); // Cleanup the event listener on component unmount
    };
  }, [userStatus]);
  const getCaretPosition = (element) => {
    let position = 0;
    const selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
    }
    return position;
  };

  const shareLink = async () => {
    try {
        // Get the current origin (e.g., "http://localhost:3000" or "https://app.transkriptor.com")
        let baseUrl = window.location.origin;

        let link = baseUrl + "/sharing?oid=" + orderID;

        await navigator.clipboard.writeText(link);

        showToast(t("File link copied to clipboard"));

        return true;

    } catch (err) {
        console.error('Failed to copy text: ', err);
        return false;
    }
};


  useEffect(() => {

    // Add event listeners
    window.addEventListener("keydown", listenKeyDown);

    // Cleanup
    return () => {
        window.removeEventListener("keydown", listenKeyDown);
    };
}, []);

  const listenKeyDown = (e) => {
    var kc = e.which || e.keyCode;
    if (e.ctrlKey && String.fromCharCode(kc).toUpperCase() == "S") {
      e.preventDefault();
      console.log("ctrl+s");
      
      let focusedElement = contentRefs.current.find(ref => ref === document.activeElement);
      let focusedIndex = contentRefs.current.findIndex(ref => ref === document.activeElement);
      
      console.log(focusedIndex);
      console.log(focusedElement);
      
      if (focusedElement) {
       
        
        focusedElement.blur();
      }
      saveRef.current.click()
     

    }
    if (e.ctrlKey && String.fromCharCode(kc).toUpperCase() == "M") {
      e.preventDefault();
      mergeSameSpeakers();
    }

    
  };

  const handleAddSpeaker = (speaker) => {
    console.log("asd:" + speaker);
    const temp_speakerList = [...speakerList];
    temp_speakerList.push(speaker);
    setSpeakerList(temp_speakerList);    
  };
	
  const handleChangeSpeaker = (speaker, index) => {
    const updatedContent = [...content];
    updatedContent[index].Speaker = speaker;
    setContent(updatedContent); 
  };

  const handleRenameSpeaker = (speaker, newSpeaker) => {
    const temp_speakerList = [...speakerList];
    const index = temp_speakerList.indexOf(speaker);
    temp_speakerList[index] = newSpeaker;
    setSpeakerList(temp_speakerList); 
    const updatedContent = [...content];
    updatedContent.forEach((item) => {
      if(item.Speaker === speaker){
        item.Speaker = newSpeaker;
      }
    });
    setContent(updatedContent); 
  };
  
 
  const reInitate = async () => {
    setIsLoading(true);
    const response = await fetchData("https://95dqqor8sd.execute-api.eu-central-1.amazonaws.com/default/reInitiator?orderid=" + orderID);
    getTranscript();
    setIsLoading(false);
  };
  useEffect(() => {
    const beforeUnloadHandler = (e) => {
        const message = "You have unsaved changes! Are you sure you want to leave?";
        e.preventDefault();
        e.returnValue = message;
        return message;
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
}, []);
useEffect(() => {
    
  // Define the callback function first
  window.googleTranslateElementInit = function() {
    new window.google.translate.TranslateElement('google_translate_element');
  }

  setInterval(() => {
    if (document.getElementById("google_translate_element") && document.querySelector('#google_translate_element').childNodes.length === 0) {
      const addScript = document.createElement('script');
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      );
      document.body.appendChild(addScript);
    }
  }, 3000);

  // Check for duplicates and remove extras, leaving only one instance
  const duplicateCheckInterval = setInterval(() => {
    const translateWidgets = document.querySelectorAll('.goog-te-gadget');
    if (translateWidgets.length > 1) {
      for (let i = 1; i < translateWidgets.length; i++) { // Start from 1 to leave the first element
        translateWidgets[i].remove();
      }
    }
  }, 1000);

  let selectedLanguage = "";

  const languageChangeInterval = setInterval(() => {
    const translateCombo = document.querySelector('.goog-te-combo');
    if (translateCombo) {
      const currentLanguage = translateCombo.value;
      if (currentLanguage && currentLanguage !== selectedLanguage) {
        selectedLanguage = currentLanguage;
        console.log("Language changed to", selectedLanguage);
        scrollBottom();  // Assuming scrollBottom is defined elsewhere in your component
      }
    }
  }, 500);

  // Cleanup on component unmount
  return () => {
    const existingScript = document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]');
    if (existingScript) {
      existingScript.remove();
    }
    if (document.getElementById("google_translate_element")) {
      document.getElementById("google_translate_element").innerHTML = "";
    }
    delete window.googleTranslateElementInit;
    clearInterval(languageChangeInterval);
    clearInterval(duplicateCheckInterval);
  };

}, []);



function scrollBottom() {
    const list = (document.getElementsByClassName("transkript-list"));
    console.log("kekke")
    console.log("list", list);
    console.log("list.length", list.length);
    if (list.length > 0) {
      list[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      for(let i=0;i<list.length;i+=20){
        setTimeout(() => {

          list[i].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }, 50 * i);
      }
      list[list.length-1].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }

}


const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const min = Math.floor(seconds / 60);
  const sec = Math.floor(seconds % 60);

  if (hours > 0) {
      return `${hours}:${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
  } else {
      return `${min}:${sec.toString().padStart(2, "0")}`;
  }
};


  const handleSliderChange = (e) => {
    setFontSize(parseInt(e.target.value));
    localStorage.setItem("fontSize", parseInt(e.target.value));
    applyFill(e.target);
  };

  const handleUndo = (e) => {
    console.log("undo");
    e.preventDefault();
    document.execCommand('undo', false, null);
  }

  const handleRedo = (e) => {
    console.log("redo");
    e.preventDefault();
    document.execCommand('redo', false, null);
  }
  useEffect(() => {


    if (undoRef.current) {
      undoRef.current.addEventListener('click', handleUndo, true);
    }

    if (redoRef.current) {
      redoRef.current.addEventListener('click', handleRedo, true);
    }

    return () => {
      if (undoRef.current) {
        undoRef.current.removeEventListener('click', handleUndo);
      }

      if (redoRef.current) {
        redoRef.current.removeEventListener('click', handleRedo);
      }
    }
  }, []);
  
  // Render the component
  useEffect(() => {
    if(content){
      const nonEmptyItem = content.find(item => item.text.trim());
    
      if (nonEmptyItem) {
        const speakers = [...new Set(content.map(item => item.Speaker || "Speaker 1"))];
        setSpeakerList(speakers);
      }
    }

  }, [content]);
  
  
  return (
    <>
      <div className="container-fluid p-0">
      
        <div className="row justify-content-center">
          <div className="col-12">
            {isLoading &&  !content ? (
              <>
			  	<div className='d-flex justify-content-center text-center'>
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
    			</div>
			  </>
            ) : (
				status === "Failed" ? (
					<>
						<Failed
						failCode={failCode}
						/>
					</>
				) : status === "Processing" ? (
					<>
					<Processing
						onRefresh={getTranscript}
					/>
					</>
				): status === "Completed" ? (
					<>
					<div className="card card-padding overflow-visible">
                <div className="row align-items-center">
                  <div className="col-xxl-4 col-lg-4 col-md-4 col-12 mb-2 order-xxl-1 order-lg-1 order-md-1 order-1 text-md-start text-center notranslate">
                    <h2 class="small-title regular mb-2">
                      <span class="me-2 burger-web-view">
                        <img
                          src={filesMarkSvg}
                          class="img-fluid"
                          height="auto"
                          width="28px"
                          alt="img"
                        />
                      </span>
                      <span class="me-2 burger-mobile-view" onClick={()=> handleOpenSidebar()}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                      </span>
                      <div className="filename-container">
                          <span className="filename">{filename}</span>
                          <Link
                              onClick={() =>
                                  showModal(
                                      "RENAME",
                                      { name: filename, tid: tid, uid: uid },
                                      { onRename }
                                  )
                              }
                              className="web-view"
                              style={{display: oid && oid.length > 0 ? "none" : ""}}
                          >
                                                  <span
                          class="icons-hover"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={t("Rename")}
                          data-bs-original-title="Rename"
                        >
                          <img src={renameSvg} class="img-fluid" alt="img" />
                        </span>
                          </Link>
                      </div>

                      
                    </h2>

                    <p class="d-sm-inline-block d-block ft-14 mb-1 me-sm-4 me-0 web-view">
                      <span class="me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 19.2 18"
                        >
                          <g id="date-line" transform="translate(-2 -2)">
                            <path
                              id="Path_135"
                              data-name="Path 135"
                              d="M20.15,6H18.2V7.2H20V20.4H3.2V7.2H5V6H3.05A1.068,1.068,0,0,0,2,7.086V20.514A1.068,1.068,0,0,0,3.05,21.6h17.1a1.068,1.068,0,0,0,1.05-1.086V7.086A1.068,1.068,0,0,0,20.15,6Z"
                              transform="translate(0 -1.6)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_136"
                              data-name="Path 136"
                              d="M8,14H9.2v1.2H8Z"
                              transform="translate(-2.4 -4.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_137"
                              data-name="Path 137"
                              d="M14,14h1.2v1.2H14Z"
                              transform="translate(-4.8 -4.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_138"
                              data-name="Path 138"
                              d="M20,14h1.2v1.2H20Z"
                              transform="translate(-7.2 -4.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_139"
                              data-name="Path 139"
                              d="M26,14h1.2v1.2H26Z"
                              transform="translate(-9.6 -4.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_140"
                              data-name="Path 140"
                              d="M8,19H9.2v1.2H8Z"
                              transform="translate(-2.4 -6.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_141"
                              data-name="Path 141"
                              d="M14,19h1.2v1.2H14Z"
                              transform="translate(-4.8 -6.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_142"
                              data-name="Path 142"
                              d="M20,19h1.2v1.2H20Z"
                              transform="translate(-7.2 -6.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_143"
                              data-name="Path 143"
                              d="M26,19h1.2v1.2H26Z"
                              transform="translate(-9.6 -6.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_144"
                              data-name="Path 144"
                              d="M8,24H9.2v1.2H8Z"
                              transform="translate(-2.4 -8.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_145"
                              data-name="Path 145"
                              d="M14,24h1.2v1.2H14Z"
                              transform="translate(-4.8 -8.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_146"
                              data-name="Path 146"
                              d="M20,24h1.2v1.2H20Z"
                              transform="translate(-7.2 -8.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_147"
                              data-name="Path 147"
                              d="M26,24h1.2v1.2H26Z"
                              transform="translate(-9.6 -8.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_148"
                              data-name="Path 148"
                              d="M9.6,6.8a.6.6,0,0,0,.6-.6V2.6A.6.6,0,0,0,9,2.6V6.2A.6.6,0,0,0,9.6,6.8Z"
                              transform="translate(-2.8)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_149"
                              data-name="Path 149"
                              d="M25.6,6.8a.6.6,0,0,0,.6-.6V2.6a.6.6,0,0,0-1.2,0V6.2A.6.6,0,0,0,25.6,6.8Z"
                              transform="translate(-9.2)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_150"
                              data-name="Path 150"
                              d="M13,6h6V7.2H13Z"
                              transform="translate(-4.4 -1.6)"
                              fill="#6b6c6d"
                            />
                          </g>
                        </svg>
                      </span>
                     {date}
                    </p>
                    <p class="d-sm-inline-block d-block ft-14 mb-1 web-view">
                      <span class="me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 16.875 16.875"
                        >
                          <g id="clock" transform="translate(-1.125 -1.125)">
                            <path
                              id="Path_151"
                              data-name="Path 151"
                              d="M17.966,6.715H16.841v6.261l4.064,1.932.483-1.016-3.423-1.627Z"
                              transform="translate(-7.858 -2.795)"
                              fill="#6b6c6d"
                            />
                            <path
                              id="Path_152"
                              data-name="Path 152"
                              d="M9.563,1.125A8.438,8.438,0,1,0,18,9.563,8.437,8.437,0,0,0,9.563,1.125Zm0,15.75a7.313,7.313,0,1,1,7.313-7.312A7.313,7.313,0,0,1,9.563,16.875Z"
                              fill="#6b6c6d"
                            />
                          </g>
                        </svg>
                      </span>
                      {formatTime(duration)}
                    </p>
                  </div>
                  <div class="col-xxl-5 col-lg-4 col-md-4 col-12 order-xxl-2 order-lg-2 order-md-3 order-3">
                    <div class="row align-items-center">
                      <div class="col-md-6" style={{overflow:"hidden"}}>
                      <div id="google_translate_element" className="google-div"></div>

          
                      </div>
                      <div class="col-md-6 web-view notranslate">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                          <p class="mb-0">Aa</p>
                          <div class="water-slider text-slider w-100 mx-3">
                            <div class="range-slider">
                              <span class="range-slider__value"></span>
                              <input
                                id="range-slider-val"
                                class="range-slider__range browser-default"
                                type="range"
                                ref={fontSizeRef}
                                min="8" max="18" value={fontSize} 
                                onChange={handleSliderChange} onClick={handleSliderChange}
                
                              />
                            </div>
                          </div>
                          <p class="small-title mb-0">Aa</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-lg-4 col-md-4 col-12 order-xxl-3 order-lg-3 order-md-2 order-2 text-md-end text-center notranslate">
                    <div class="file-action-icon-flex mb-3">
                      <Link ref={undoRef} onClick={handleUndo} style={{display: "none"}} className="hover-effect">
                        <span
                          class="file-action-list"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={t("Undo")}
                          >
                          <img src={undoSvg} class="img-fluid" alt="img" />
                        </span>
                      </Link>
                      <Link ref={redoRef} style={{display: "none"}} className="hover-effect">
                        <span
                          class="file-action-list"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={t("Redo")}
                          ref={redoRef}
                        >
                          <img src={redoSvg} class="img-fluid" alt="img" />
                        </span>
                      </Link>
                      <Link
                        onClick={(e)=> {
                          e.preventDefault();
                          showModal("SHORTCODES")
                        }}
                        className="web-view hover-effect"
                      >
                        <span
                          class="file-action-list theme-color"
                
                        >
                           <span className="hover-span">
                          {t("Shortcodes")}
                          </span> 
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="19"
                            viewBox="0 0 21 19"
                          >
                            <g
                              id="Group_45"
                              data-name="Group 45"
                              transform="translate(-0.16 -0.34)"
                            >
                              <path
                                id="Rectangle_36"
                                data-name="Rectangle 36"
                                d="M0,0H21a0,0,0,0,1,0,0V9a4,4,0,0,1-4,4H4A4,4,0,0,1,0,9V0A0,0,0,0,1,0,0Z"
                                transform="translate(0.16 6.34)"
                                fill="#fff"
                              />
                              <path
                                id="Rectangle_37"
                                data-name="Rectangle 37"
                                d="M4,0H17a4,4,0,0,1,4,4V5a0,0,0,0,1,0,0H0A0,0,0,0,1,0,5V4A4,4,0,0,1,4,0Z"
                                transform="translate(0.16 0.34)"
                                fill="#fff"
                              />
                              <path
                                id="code"
                                d="M8.235,4.5l1.016.207L7.312,13.834,6.3,13.627,8.235,4.5m3.474,4.667L9.848,7.305V5.838l3.329,3.329L9.848,12.491V11.024l1.862-1.856m-9.339,0L5.7,5.838V7.305L3.838,9.167,5.7,11.024v1.468Z"
                                transform="translate(2.887 3.953)"
                                fill="currentColor"
                              />
                            </g>
                          </svg>
                        </span>
                      </Link>
                      <Download content={content} filename={filename} audio={audio} contentRefs={contentRefs}/>
                      <Link
						            onClick={(e)=>{
                          e.preventDefault(); // Prevent default action.
                          saveTranscription();
                        }}
                        className="hover-effect"
                      >
                        <span
                          class="file-action-list theme-color"
                         
                          ref={saveRef}
                        >
                           <span className="hover-span">
                          {t("Save")}
                          </span> 
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.5"
                            height="20.535"
                            viewBox="0 0 20.5 20.535"
                          >
                            <g
                              id="Group_47"
                              data-name="Group 47"
                              transform="translate(0 0)"
                            >
                              <rect
                                id="Rectangle_39"
                                data-name="Rectangle 39"
                                width="20.5"
                                height="20.5"
                                rx="5"
                                transform="translate(0 0.035)"
                                fill="#f9fcff"
                              />
                              <path
                                id="Rectangle_40"
                                data-name="Rectangle 40"
                                d="M4,0H6.66a4,4,0,0,1,4,4V6.56a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
                                transform="translate(4.92 10.695)"
                                fill="currentColor"
                              />
                              <path
                                id="Rectangle_41"
                                data-name="Rectangle 41"
                                d="M0,0H10.66a0,0,0,0,1,0,0V2.56a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V0A0,0,0,0,1,0,0Z"
                                transform="translate(4.92 0.035)"
                                fill="currentColor"
                              />
                              <rect
                                id="Rectangle_42"
                                data-name="Rectangle 42"
                                width="4.1"
                                height="4.938"
                                rx="2.05"
                                transform="matrix(1, -0.017, 0.017, 1, 9.43, 0.072)"
                                fill="#f9fcff"
                              />
                            </g>
                          </svg>
                        </span>
                      </Link>
                      <Link class="share_btn hover-effect" onClick={(e)=> {
                        e.preventDefault();
                        shareLink()
                      }}>
                        <span
                          class="file-action-list"
                 
                        >
                         <span className="hover-span">
                          {t("Share")}
                          </span> 
                          <img src={shareSvg} class="img-fluid" alt="img" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr class="mt-0" />
                <div class="files-tab-div-height">
                  <ul
                    class="nav nav-pills btn-pills mb-3 notranslate"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-traskript-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-traskript"
                        type="button"
                        role="tab"
                        aria-controls="pills-traskript"
                        aria-selected="true"
                      >
                        <span class="btn-tab-icon">
                          <img
                            src={tabTranskriptorBlue}
                            class="img-fluid btn-reg-icon"
                            alt="img"
                          />
                          <img
                            src={tabTranskriptorWhite}
                            class="img-fluid btn-active-icon"
                            alt="img"
                          />
                        </span>
                        Transkript
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-chat-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-chat"
                        type="button"
                        role="tab"
                        aria-controls="pills-chat"
                        aria-selected="false"
                      >
                        <span class="btn-tab-icon me-1">
                          <img
                            src={tabChatBotBlue}
                            class="img-fluid btn-reg-icon"
                            alt="img"
                          />
                          <img
                            src={tabChatBotWhite}
                            class="img-fluid btn-active-icon"
                            alt="img"
                          />
                        </span>
                        {t("AI Chat")}
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                   {isLoading &&  content ? (
                    <>
                <div className='d-flex justify-content-center text-center'>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>):null}
                    <div
                      class="tab-pane fade show active"
                      id="pills-traskript"
                      role="tabpanel"
                      aria-labelledby="pills-traskript-tab"
                    >
                      <div class="transkript-list-res-height">
                        <div class="row gx-0 w-100 m-0">
                          <div className="col-12">
                          {content && content.length > 0 ? (
                            content.every(item => !item.text.trim()) ? (
                                <p className="text-center">{t("Make sure that the audio/video file has sound!")}</p>
                            ) : (
                                <>
                                {(() => {
                                

                                    return content.map((item, index) => (
                                        <Transcription
                                            key={index}
                                            tabIndex={0}
                                            item={item}
                                            index={index}
                                            goToBlock={goToBlock}
                                            handleTranscriptContent={handleTranscriptContent}
                                            handleKeyDown={handleKeyDown}
                                            onAddSpeaker={handleAddSpeaker}
                                            speakerList={speakerList}
                                            changeSpeaker={handleChangeSpeaker}
                                            onRenameSpeaker={handleRenameSpeaker}
                                            fontSize={fontSize}
                                            ref={(el) => (contentRefs.current[index] = el)}
                                            focus={focus}
                                            slideFocus={slideFocus}
                                        />
                                    ));
                                })()}
                            </>
                        )
                    ) : null}


                          </div>
                        </div>
                      </div>
             
                      <div class="row w-100 m-0 sec-pad-top justify-content-center">
                        <div class="col-xxl-8 card-margin">
                          <div class="card" style={{display: userStatus ? userStatus !== "Trial" ? "none": "" : "none"}}>
                            <div class="card-header theme-bg py-3 px-4">
                              <h2 class="mini-title white-color semibold text-center mb-0">
                               {t("TRIAL")}
                              </h2>
                            </div>
                            <div class="card-padding">
                              <div class="d-md-flex d-block align-items-center text-center text-md-start">
                                <img
                                  src={logoIcon}
                                  class="img-fluid me-3 mb-2"
                                  alt="img"
                                />
                                <div>
                                  <p class="ft-18 dark-color medium">
                                    {t("Created with Transkriptor Trial Account")}
                                  </p>
                                  <p>
                                    {t("Unlock the full version of Transkriptor. Trial version has duration limit per transcription and limited features. Upgrade now to meet with the most comprehensive A.I. productivity tool.")}
                                  </p>
                                </div>
                              </div>
                              <div class="text-center mt-3">
                                <Link to="/payment" target="_blank" class="btn theme-btn">
                                  {t("GET FULL VERSION")}
                                  <span class="ms-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      class="bi bi-arrow-right-circle"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                                      />
                                    </svg>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div class="card" style={{display: userStatus && userStatus !== "Trial" && userTrial ? "": "none"}}>
                            <div class="card-header theme-bg py-3 px-4">
                              <h2 class="mini-title white-color semibold text-center mb-0">
                                TRANSKRIPTOR
                              </h2>
                            </div>
                            <div class="card-padding">
                              <div class="d-md-flex d-block align-items-center text-center text-md-start">
                                <img
                                  src={logoIcon}
                                  class="img-fluid me-3 mb-2"
                                  alt="img"
                                />
                                <div>
                                  <p class="ft-18 dark-color medium">
                                    {t("Created with Transkriptor Trial Account")}
                                  </p>
                                  <p>
                                    {t("Unlock the full version of Transkriptor. Click the button below to get the full transcription!")}
                                  </p>
                                </div>
                              </div>
                              <div class="text-center mt-3">
                                <Link onClick={()=> reInitate()} class="btn theme-btn" style={{backgroundColor: "#1f9739", border: "none"}}>
                                 {t("Now, click to get the full transcription!")}
                                  <span class="ms-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      class="bi bi-arrow-right-circle"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                                      />
                                    </svg>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                   
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-chat"
                      role="tabpanel"
                      aria-labelledby="pills-chat-tab"
                    >
                 <AiChat orderId={orderID} tid={tid}/>
                    </div>
                  </div>
                </div>

          </div>
					</>
				): (<></>)
			  
            )}
          </div>
        </div>
      </div>
      {status === "Completed" ? (
		<>
		<Player
		 audio={audio} 
		 focusedTime={focusedTime}
		 onDurationChange={setDuration}
     applyFill={applyFill}
     setFocus={setFocus}
     setSlideFocus={setSlideFocus}
     playBlock={playBlock}
     // This function updates the totalDuration state in the parent
		 />
		</>
	  ):(
		<></>
	  )}
    </>
  );
}

export default Editor;
