import React, { useEffect, useState } from 'react';
import { useApiData } from '../hooks/useApiData';
import { useAuth } from '../Auth/AuthContext';

import { fetchData, fetchDataPost } from '../hooks/apiUtils';
import { showToast } from '../components/common/toast';
import {useModal} from '../components/common/Modal/ModalContext'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import profilePng from "../assets/img/profile.png"
import { useTranslation } from '../components/common/LanguageProvider';
function Billing() {

    const { t } = useTranslation();
    const { currentUser, hashedId, logout, sendResetPasswordMail } = useAuth();
	const  navigate  = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [status, setStatus] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
    const { showModal } = useModal();
    const [subscriptionType, setSubscriptionType] = useState(null);
    const [renewalDate, setRenewalDate] = useState('');
    const [isRewarded, setIsRewarded] = useState(false);
    const isChrome = window.navigator.userAgent.includes("Chrome")

    const getAccountDetails = async () => {
        const data = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid="+ hashedId);
        console.log(data);
        setEmail(data.Mail);
        setStatus(data.SStatus);
        setApiKey(hashedId);
        setName(currentUser.displayName ? currentUser.displayName : currentUser.email.split('@')[0]);
        setPhotoUrl(currentUser.photoURL ? currentUser.photoURL : profilePng);
        setIsLoading(false);
        setSubscriptionType(data.Subscription ? data.Subscription.Subscription_Type : null);
        setRenewalDate(data.Subscription ? formatDate(data.Subscription.RenewalDate) : null)
    };
    useEffect(() => {
        getAccountDetails();
        checkIsRewarded();
    }, [hashedId]);

    const checkInvoices = async () => {
        setIsLoadingInvoices(true);
        const response = await fetchData("https://gf5c4w9xq7.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-CreateCustomerPortal-v2?cid="+ hashedId);
        const url = response["redirectUrl"];
        setIsLoadingInvoices(false);
        if(isChrome)
            window.open(url, "_blank");
        else{
            window.location.href = url;
        }
    };
    function formatDate(ms) {
        console.log(ms)
        const date = new Date(parseInt(ms) * 1000);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }
    const onCancelSubscription = (selectedReason, otherReason) => {
        if(selectedReason === "poor-quality" || selectedReason === "missing-features"){
			showModal("CANCEL-SUBSCRIPTION-COMMENT", {reason: selectedReason},{onCancelSubscriptionComment});
		}else{
			showModal("CANCEL-SUBSCRIPTION-COMPLETE",{reason: selectedReason, comment: otherReason, additional: "", isRewarded: isRewarded}, {onCancelSubscriptionComplete});
		}
    };

    const onCancelSubscriptionComment = (selectedReason, otherReason, additional) => {
        showModal("CANCEL-SUBSCRIPTION-COMPLETE",{reason: selectedReason, comment: otherReason, additional: additional, isRewarded: isRewarded}, {onCancelSubscriptionComplete});
        if(!isRewarded){
            document.querySelector('.modal-content').classList.add('theme-bg');
            document.querySelector('.modal-content').classList.add('white-color');
            document.querySelector('.modal-dialog').classList.add('modal-lg');
        }
    };

    const onCancelSubscriptionComplete = async (selectedReason, otherReason, additional, isRewarded) => {
        console.log(isRewarded)
        if(isRewarded){
            setIsLoading(true);
            const response = await fetchData("https://wa3e2dac2m.execute-api.eu-central-1.amazonaws.com/default/Churn-Flow-Cancel-Subscription?cid=" + hashedId);
            if(response){
                const data = {cid: hashedId, email: currentUser.email, reason: selectedReason, detail1: otherReason, detail2: additional, type: "SubCancel"}
                const response = fetchDataPost("https://a426uvhnw8.execute-api.eu-central-1.amazonaws.com/default/Churn-Flow-User-Feedback",
                {cid: hashedId, email: currentUser.email, reason: selectedReason, detail1: otherReason, detail2: additional, type: "SubCancel"});
                console.log(data)
                setIsLoading(false);
                showModal("CANCEL-SUBSCRIPTION-MINUTES");
                
            }
        }else{
            setIsLoading(true);
            const response = await fetchData("https://7wvf5pd74i.execute-api.eu-central-1.amazonaws.com/default/Churn-Flow-Give-1-Month-Free?cid=" + hashedId);
            if(response){ 
                setIsLoading(false);
                showModal("CANCEL-SUBSCRIPTION-GIFT");
                document.querySelector('.modal-content').classList.add('theme-bg');
                document.querySelector('.modal-content').classList.add('white-color');
                document.querySelector('.modal-dialog').classList.add('modal-lg');                
            }

        }
    };

    const checkIsRewarded = async () => {
        const response = await fetchData("https://7wvf5pd74i.execute-api.eu-central-1.amazonaws.com/default/Churn-Flow-Give-1-Month-Free?check=true&cid=" + hashedId);

        if(response !== "not rewarded"){
            setIsRewarded(true);
        }
    };
    
    
    return (
        <>
            <div class="row">
				<div class="col-12">
					<h2 class="card-title">{t("Billing")}</h2>
				</div>
			</div>
            <div className='row'style={{ display: isLoading ? "block" : "none" }}>
				<div className='d-flex justify-content-center'>
					<div className="spinner-border text-primary" role="status">

					</div>
				</div>


			</div>
            <div class="row">
            <div class="col-lg-6 card-margin">
					<div class="card card-padding card-100">
						<div class="text-lg-start text-center">
							<div class="d-sm-inline-flex d-inline-block align-items-center mb-2">
								<img src={photoUrl} class="img-fluid account-profile mb-3" alt="icon"/><br></br>
								<div class="text-sm-start text-center ps-4">
									<h3 class="small-title regular mb-1">{name}</h3>
									<p class="mb-2"> &nbsp;{t(status)}</p>
									<p class="mb-1">
										<span class="me-2 theme-color">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17.826 17.826">
											  	<path id="Path_256" data-name="Path 256" d="M2.887,2.25H19.44l.637.637V19.44l-.637.637H2.887L2.25,19.44V2.887ZM3.523,18.8H18.8V3.523H3.523ZM4.8,4.8H7.343V17.53H4.8Zm7.64,0H9.89v7.64h2.547Zm2.547,0H17.53V14.983H14.983Z" transform="translate(-2.25 -2.25)" fill="currentColor" fill-rule="evenodd"/>
											</svg>
										</span>{t("Type:")} {subscriptionType}
									</p>
									<p className='mb-1' style={{ display: renewalDate ? "" : "none" }} >
										<span class="me-2 theme-color">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19.826 19.826">
											  	<path id="Path_257" data-name="Path 257" d="M9.452,8.461V4.5m7.923,3.961V4.5M8.461,12.423h9.9m-11.884,9.9H20.345a1.981,1.981,0,0,0,1.981-1.981V8.461a1.981,1.981,0,0,0-1.981-1.981H6.481A1.981,1.981,0,0,0,4.5,8.461V20.345A1.981,1.981,0,0,0,6.481,22.326Z" transform="translate(-3.5 -3.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
											</svg>
										</span>{t("Renewal Date:")} {renewalDate}
									</p>
								</div>
							</div>
						</div>
						<div class="text-center">
							<p>{t("To upgrade or cancel your current subscription plan")}</p>
						</div>
						<div class="text-center">
							<button class="btn theme-btn red-border-btn min-btn-width mt-1 me-1" style={{ display: status === "Active" ? "" : "none" }} onClick={()=> showModal("CANCEL-SUBSCRIPTION", {onCancelSubscription})}>{t("Cancel Subscription")}</button>
							<Link to="/payment" class="btn theme-btn mt-1" >
								<span class="btn-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
									  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"></path>
									</svg>
								</span>{t("Upgrade")}
							</Link>
						</div>
					</div>
				</div>
				<div class="col-lg-6 card-margin">
					<div class="card card-padding card-100">
						<div class="text-center">
							<div class="round-theme-icon theme-color mb-3">
								<svg xmlns="http://www.w3.org/2000/svg" width="48.773" height="44.376" viewBox="0 0 48.773 44.376">
								  <g id="noun-news-5889222" transform="translate(-18.55 -25.41)">
								    <path id="Path_238" data-name="Path 238" d="M57.341,25.41H37.111a9.995,9.995,0,0,0-9.928,8.936,10,10,0,0,0-8.633,9.892V59.8a9.98,9.98,0,0,0,9.981,9.981h28.81A10,10,0,0,0,67.323,59.8V53.7a1.782,1.782,0,0,0-3.565,0v6.1a6.42,6.42,0,0,1-6.417,6.417H28.531A6.41,6.41,0,0,1,22.115,59.8V44.238a6.426,6.426,0,0,1,5.014-6.256V59.216a1.782,1.782,0,0,0,3.565,0V35.391a6.42,6.42,0,0,1,6.417-6.417h20.23a6.42,6.42,0,0,1,6.417,6.417v8.965a1.782,1.782,0,0,0,3.565,0V35.391a10,10,0,0,0-9.981-9.981Z" transform="translate(0 0)" fill="currentColor"/>
								    <path id="Path_239" data-name="Path 239" d="M70.818,49.752a1.78,1.78,0,0,0-1.782-1.782H50.552a1.782,1.782,0,0,0,0,3.565H69.036A1.78,1.78,0,0,0,70.818,49.752Z" transform="translate(-12.265 -9.156)" fill="currentColor"/>
								    <path id="Path_240" data-name="Path 240" d="M64.877,62.842a1.78,1.78,0,0,0-1.782-1.782H50.552a1.782,1.782,0,0,0,0,3.565H63.095A1.78,1.78,0,0,0,64.877,62.842Z" transform="translate(-12.265 -14.469)" fill="currentColor"/>
								    <path id="Path_241" data-name="Path 241" d="M50.552,74.97a1.782,1.782,0,0,0,0,3.565h6.773a1.782,1.782,0,0,0,0-3.565H50.552Z" transform="translate(-12.265 -20.115)" fill="currentColor"/>
								  </g>
								</svg>
							</div>
							<p class="ft-18-title mb-2">{t("Manage Billing")}</p>
							<p>{t("To check your receipts, or update your billing information please.")}</p>
						</div>
                        <div className='row'style={{ display: isLoadingInvoices ? "block" : "none" }}>
                            <div className='d-flex justify-content-center'>
                                <div className="spinner-border text-primary" role="status">

                                </div>
                            </div>


                        </div>
						<div class="text-center">
							<button class="btn theme-btn mt-3" onClick={()=> checkInvoices()}>{t("Check Subscription")}</button>
						</div>
					</div>
				</div>
            </div>
        </>
    );
}

export default Billing;
