import React, { useRef, useEffect, useState } from 'react';
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import recorditorLogo from "../../assets/img/logo_nobuffer.png"
import ReactPlayer from 'react-player';

import Editor from "../Editor/Editor";
import RecordingView from "../Recording/Recording";
function Sharing({ handleOpenSidebar }) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [reduceSize, setReduceSize] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const { oid } = useParams();
    const videoRef = useRef(null);
    const [recordingName, setRecordingName] = useState(null);
    const [date, setDate] = useState(null);
    const [videoLoading, setVideoLoading] = useState(true);
    const [duration, setDuration] = useState(null);
    const navigate = useNavigate();

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const getOrderInfo = async () => {
        try {
            const res = await fetch(`https://p8464z3n1m.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetOrderContent?oid=${oid}`);
            if (res.ok) {
                const result = await res.json();
                setOrderData(result?.recording);
                setRecordingName(result?.recording?.TempName);
                setDate(convertMsToFormattedDate(result?.recording?.Date));
                return true;
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    useEffect(() => { getOrderInfo(true); }, [
        oid
    ]);
    function convertMsToFormattedDate(ms) {
        ms = parseInt(ms);
        const date = new Date(ms);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns a 0-based value
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    }
    const handleLoadedMetadata = () => {
        const player = videoRef.current;
        if (!player) return;

        const duration = player.getDuration();
        const aspectRatio = player.getInternalPlayer().videoWidth / player.getInternalPlayer().videoHeight;
        if (aspectRatio < 1.4) {
            setReduceSize(true);
        }

        setVideoLoading(false);
        setDuration(duration);
    };

    return (
        <div class="row gx-0 w-100 m-0">
            <div className="text-center" >
                {(videoLoading) &&
                    <div className=' card-padding'>
                        <br />
                        <div className='d-flex justify-content-center text-center'>
                            <span class="spinner-border text-primary"></span>
                        </div>
                    </div>
                }

                <div className="video-container position-relative" style={{ width: reduceSize ? '40%' : '60%', margin: '0 auto' }}>
                    {orderData?.only_audio == 'true' && !isMobileDevice() &&
                        <div className="icon-overlay" style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                        }}>
                            <img src={recorditorLogo} width="70%"></img>
                        </div>}
                    <ReactPlayer
                        url={orderData?.video_url}
                        playing={true}
                        volume={1}
                        width="100%"
                        height="100%"
                        controls
                        playsinline
                        className='mb-3' id='video-src' style={{ display: videoLoading ? 'none' : null, width: '60%' }}
                        onReady={handleLoadedMetadata} ref={videoRef}
                        onContextMenu={(e) => e.preventDefault()}
                        config={{
                            attributes: {
                                disablePictureInPicture: true,
                                controlsList: 'nodownload noplaybackrate'
                            }
                        }}
                    />
                </div>
                <btn class="btn btn-primary mt-3" onClick={() => navigate('/register')}>Sign up</btn>

            </div>
        </div>
    )
}

export default Sharing;